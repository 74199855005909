$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.registration-with-deposit {

  &__form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
    max-width: 304px;
  }

  &__form-body {
    min-height: 165px;
    padding: 0 20px;
  }

  &__current-hero {
    width: 52px;
    height: 59px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: -81px;
    right: 6px;
  }

  &__have-account {
    padding-bottom: 20px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #905E3A;

    a {
      text-decoration: underline;
    }
  }

  &__block-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #6E3923;
    margin: 40px 0 8px;
  }
}
