$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.quick-registration-promotions {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 9px;
  cursor: pointer;
  background-color: #162334;
  box-shadow: 0 0 0 1px #ECFD00;

  body.rtl-lang & {
    direction: rtl;
  }

  &__input {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &:has(.is-drop-opened) {
    .quick-registration-promo.is-active {
      .quick-registration-promo__inner {
        background-color: rgba(236 253 0 / 0.08);
      }
    }

    & + .tooltip {
      animation: hide 0.5s forwards;
    }
  }
}

.quick-registration-promo {
  $root: &;
  display: flex;
  align-items: center;
  position: relative;

  &:not(:last-child) {
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      left: 0;
      top: calc(100% + 4px);
      height: 1px;
      background-color: #273242;
      opacity: 0;
    }
  }


  &.is-pnp {
    width: 100%;
  }

  &.is-promocode {
    flex-direction: column;
  }

  &.is-default {
    margin: 20px auto 22px;

    #{$root}__img {
      width: 90px;
      height: 98px;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-block: 12px;
    padding-inline: 12px 16px;
    min-height: 62px;
    border-radius: 2px;
    background-color: #1C2738;
    transition: background-color .3s ease;

    .form-radio {
      display: none;
    }
  }

  &__img-wrap {
    width: 32px;
    height: 32px;
    display: grid;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  &__img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    grid-area: 1/1;

    &.img-active {
      display: none;
    }
  }

  .wrap {
    overflow: hidden;
  }

  &__label {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;

    .radio-block {
      flex-shrink: 0;
    }

    .chose-code__img {
      width: 56px;
      height: 56px;
    }

    .chose-code__title {
      font-weight: 700;
      font-size: 12px;
      line-height: 12px;
      color: #FFFFFF;
      padding-bottom: 4px;
    }

    .chose-code__promocode {
      font-weight: 800;
      font-size: 18px;
      line-height: 20px;
      color: #FFCF01;
      text-shadow: 0px 1px 0px #CC2500, 0px 4px 4px rgba(0, 0, 0, 0.35);
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__wrap {
    color: #FFFFFF;
    display: grid;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    margin-inline-end: 10px;

    .quick-registration-dropdown:not(.open) .quick-registration-promo.is-active & {
      margin-inline-end: 20px;
    }
  }

  &__title {
    color: #A5AFC0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.28px;
  }

  &__text {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.12;

    span {
      display: inline-block;
      margin-inline-end: 3px;

      &:last-child {
        margin: 0;
        display: inline;
      }
    }
  }

  &__circle {
    display: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #162334;
    border: 1px solid #2A394E;
    margin-inline-start: auto;
    flex-shrink: 0;
    position: relative;
    transition: background-color .3s ease, border-color .3s ease;

    &::after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      background-color: #162334;
      border-radius: 50%;
      filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.30));
      opacity: 0;
      transition: opacity .3s ease, transform .3s ease;
    }

    .quick-registration-promo.is-active & {
      background-color: #ECFD00;
      border-color: #ECFD00;

      &::after {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }

  &__input-wrap {
    width: 100%;
    margin-top: 1px;
    display: none;
    align-items: center;
    justify-content: space-between;
    gap: 2px;
    height: 52px;
    padding: 10px 12px 10px 16px;
    border-radius: 0px 0px 2px 2px;
    background-color: #273242;

    &.is-opened {
      display: flex;
    }

    .btn {
      padding: 8px 12px;
      height: 32px;
      min-width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      line-height: 1;
      font-weight: 400;
      text-transform: capitalize;
      border-radius: 2px;
      border: 1px solid #ECFD00;
      flex-shrink: 0;

      &:disabled {
        color: #576477;
        background: none;
        border: 1px solid rgba(57, 69, 87, 1);

        &::after {
          background-color: transparent;
          border: 1px solid #2A394E;
        }
      }
    }
  }

  &__input {
    height: 100%;
    flex-grow: 1;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    background: transparent;
    border: none;
    width: 100%;
    text-overflow: ellipsis;

    &::placeholder {
      color: #576477;
    }
  }
}

.tooltip {
  display: block;
  text-transform: none;
  left: 0;
  transform: none;
  opacity: 0;
  animation: show 0.5s 2.2s forwards;

  body.rtl-lang & {
    left: auto;
    right: 0;
  }
}

@keyframes show {
  to {
    opacity: 1;
  }
}

@keyframes hide {
  to {
    opacity: 0;
  }
}