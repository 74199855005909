$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.balance-filter-date {
  margin-bottom: 14px;
  &__wrap {
    display: inline-flex;
    margin-bottom: 6px;
  }
  
  .table-filter__date:first-child {
    margin-inline-end: 12px;
  }
  input {
    -moz-appearance: none;
    -webkit-appearance: none;

    @include text(16px, 19px, bold);
    color: $black;
    padding: 8px;
    border-radius: 2px;
    background-color: $yellow;
    border: 0;
    height: 37px;
    &.ng-invalid {
      border: 1px solid #FF2469;
      background: rgba(255, 255, 255, 0.3);
      color: #ffffff;
    }
  }
}