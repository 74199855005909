$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.tooltip{
  position:absolute;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
  background: $red;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  padding: 8px;
  z-index: 50;
  display: none;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #FFFFFF;

  &.is-top{ bottom: calc( 100% + 10px);}
  &.is-bottom{ top: calc( 100% + 10px);}
  &.is-left{ inset-inline-start: calc( 100% + 10px);}


  &::before {
    height: 0;
    content: '';
    position:absolute;
    border-style: solid;
    border-width: 6px 5px 0 5px;
    left: calc(50% - 8px);
    border-color: transparent;
  }

  &.is-top::before{
    border-top-color: $red;
    bottom: -6px;
  }

  &.is-bottom::before{
    border-bottom: 8px solid $red;
    top: -6px;
  }

  &.is-blue{
    background: #F0F5FC;
    color: #0C1221;
    padding: 8px;

    &::before {
      border-top-color: #F0F5FC;
    }
  }




  &__row{
    display: flex;
    justify-content: space-between;
    &:not(:first-child){ margin-top:7px;}
  }
  &__key{
    text-align: left;
    width: 120px;
    flex-shrink: 0;
    overflow: hidden;
  }
  &__val{
    text-align: right;
    width: 50px;
    flex-shrink: 0;
    overflow: hidden;
  }


}


