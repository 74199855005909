$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.form-control {
  width: 58px;
  height: 52px;

  position: absolute;
  top: 22px;
  right: 0;

  background-repeat: no-repeat;
  background-position: center;

  &.is-currency{
    width: 16px;
    height: 16px;
    top: 16px;
    left: 13px;
    color: $yellow;
    @include text(18px, 18px, 700,$yellow );
  }

  &.is-paysystem{
    top: 14px;
    left: 246px;
    width: 20px;
    height: 20px;
    background-size: 20px auto;
    background-position: center, 0 0;
    background-image: var(--icon-zimpler);
    filter: brightness(0) $filterYellow;

    .is-icon-none & {
      display: none;
    }
  }

}
body.rtl-lang .form-control {
  left: 0;
  right: auto;
}