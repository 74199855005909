$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.tournament-banner {
	height: 206px;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	margin: 0 20px 36px;
	padding-bottom: 32px;
	background-position: center top;
	background-size: cover;
	padding: 0 20px 32px 20px;

	@include s {
		margin-left: 24px;
		margin-right: 24px;
	}

	&__link {
		width: 100%;
		max-width: 246px;
		height: 56px;
		font-size: 20px;
		line-height: 56px;
		letter-spacing: 0.01em;

		@include s {
			width: auto;
			min-width: 246px;
			max-width: none;
		}
	}

}