$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
body:has(.game-providers.is-opened) {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
}

.game-providers {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 48px;
  top: 48;
  left: 0;
  background: $black;
  z-index: 3;
  transform: translateX(-100%);
  transition: transform 0.3s;
  overflow: hidden;

  @include breakpoint($bp1) {
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-row-gap: 18px;
  }

  body.linda-has-user & {
    bottom: 0;
  }

  body:has(.is-verification-changed) & {
    top: 76px;
  }

  body.rtl-lang & {
    direction: rtl;
    transform: translateX(100%);
  }

  &.is-opened {
    transform: translateX(0);
    transition: transform 0.3s;

    body.rtl-lang & {
      transform: translateX(0);
    }
  }
  &__header {
    height: 48px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-inline-end: 20px;
    margin-top: 12px;
  }
  &__btn-close {
    margin: 0 4px;

    body.rtl-lang & {
      scale: -1 1;
    }
  }
  &__title {
    width: 100%;
    max-width: calc(100% - 56px);
    font-size: 16px;
    line-height: 19px;
    border-bottom: 2px solid $red;
    padding-bottom: 3px;
    text-transform: uppercase;
    @include breakpoint($bp1) {
      width: auto;
    }
  }
  &__container {
    -webkit-overflow-scrolling: touch;
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include breakpoint($bp1) {
      grid-column: -1/1;
    }
    @include breakpoint($bp2) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &__item {
    height: 56px;
    width: 100%;
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 4px 20px;
    font-size: 16px;
    line-height: 19px;
    color: $grey;
    @include breakpoint($bp2) {
      width: calc(100% / 2);
      &:nth-child(2) {
        padding: 0 20px 4px;
        height: 52px;
      }
    }
    @include breakpoint($bp3) {
      width: calc(100% / 3);
      &:nth-child(3) {
        padding: 0 20px 4px;
        height: 52px;
      }
    }
    &:nth-child(1) {
      padding: 0 20px 4px;
      height: 52px;
    }
    &:nth-child(odd) {
      @include breakpoint($bp2) {
        border-inline-end: 1px solid $blue;
      }
    }
    &:nth-child(2n + 2) {
      @include breakpoint($bp3) {
        border-inline-end: 1px solid $blue;
      }
    }
    &-title {
      display: flex;
      align-items: center;
    }
    &-img {
      margin-inline-end: 13px;
      width: 24px;
      height: 24px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    @include hover() {
      color: $yellow;
      text-decoration: none;
      .game-providers__item-img {
        background-position: right center;
      }
    }
  }

  &__sort {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 8px;
    margin: 4px 20px 0;
    @include breakpoint($bp1) {
      grid-template-columns: repeat(2, 136px);
      justify-content: flex-end;
      margin: 14px 20px 0 0;
    }
  }
  &__btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 0;
    padding: 13px 10px 12px;
    border-radius: 2px;
    border: 0;
    background-color: #273242;
    text-align: center;
    cursor: pointer;
    transition: background-color var(--animation-duration);

    @include hover {
      background-color: $lightBlue;
    }

    &::before {
      content: attr(data-text);
      @include text(16px, 19px, 400, $blueGray);
      @include ellipsis;
      transition: color var(--animation-duration);
    }

    &::after {
      flex-shrink: 0;
      display: block;
      width: 15px;
      height: 15px;
      background: var(--icon-arrow-sort) top 1px center / contain no-repeat;
      transition: transform var(--animation-duration);
    }

    &.linda-brands-ordered-by-name-asc,
    &.linda-brands-ordered-by-name-desc,
    &.linda-brands-ordered-by-count-asc,
    &.linda-brands-ordered-by-count-desc {
      &::before {
        color: $white;
      }
      &::after {
        content: '';
      }
    }

    &.linda-brands-ordered-by-count-desc::after,
    &.linda-brands-ordered-by-name-desc::after {
      transform: rotate(180deg);
    }

    &.linda-brands-ordered-by-name-desc::before {
      content: 'z-A';
    }
  }
}
