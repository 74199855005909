$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.a2hs-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 20px 14px 12px;
    border-top: 1px solid #A5AFC0;
    border-bottom: 1px solid #A5AFC0;
    background: $blue;
    &__left {
        display: flex;
        align-items: center;      
        flex-grow: 1;  
    }
    &__close {
        width: 20px;
        height: 20px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 12px;
        display: block;
    }
    &__info {
        margin-right: 12px;
        flex-grow: 1;
    }
    &__title {
        font-weight: bold;
        font-size: 14px;
        line-height: 16px; 
        color: $white;
    }
    &__description {
        font-size: 14px;
        line-height: 16px;
        color: #A5AFC0;
    }
    &__btn {
        padding: 0 8px;
        min-width: 61px;
    }
}