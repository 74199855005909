$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.info_sitemap{
    &__list{
        overflow: auto;
        max-width: 100%;
        -webkit-overflow-scrolling: touch;
        margin-bottom:  14px;
        display: grid; 
        grid-gap: 8px;
        grid-template-columns: 156px repeat(4, 136px ) 156px;
        grid-auto-rows: 48px;
        padding-bottom: 10px;
        &.is-sport {
            grid-template-columns: unset;
            grid-auto-flow: column;
            grid-auto-columns: 146px;
        }
    }

    @at-root .info &__title{
        font-weight: 700;
        padding-bottom: 12px;
    }

    &__item{
        align-self: center;
        max-height: 100%;
        overflow: hidden;
        &:nth-child(6n - 5){
            width: 156px;
            padding-inline-start: 20px;
        }
        &:nth-child(6n){
            width: 156px;
            padding-inline-end: 20px;
        }
    }

    &__link{
        color: $grey;
        max-width: 100%;
        @include hover(){
            color: #CBD0D8;
            text-decoration: none;
        }
    }

}