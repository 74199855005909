$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.game-item-play{
    position: relative;
    width: 180px;
    height: 270px;
    margin-bottom: 20px;
    border-radius: 2px;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
    background-size: cover, 100%;
    background-repeat: no-repeat;
    background-position: center, 1px 0px;


    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    &__title{
        max-width: calc(100% - 32px);
        text-align: center;
        overflow: hidden;
         @include text(16px, 20px, 700, #fff);
        margin-top: 38px;
        margin-bottom: 12px;
        min-height: 20px;
    }
    &__play{
        width: calc(100% + 20px);
        margin-bottom: 28px;
    }
    &__demo{
        @include text(16px, 20px, 400, #fff);
        padding: 0 10px;
        max-width: 100%;
        @include ellipsis;
    }

    

    &__fav{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
        top: 4px;
        inset-inline-end: 4px;
        width: 48px;
        height: 48px;
        &::after, &::before{
            position: absolute;
            background-position: center;
            width: 24px;
            height: 24px;
            transition: .2s ease transform;  
        }
        &::before{
            transform: scale(0);
            opacity: 0;  
        }
        &::after{
            transform: scale(1);
            opacity: 1;  
        }
        
        &.is-favourite{
            &::before{
                transform: scale(1);            
                opacity: 1;
            }
            &::after{
                transform: scale(0);
                opacity: 0; 
            }
        }
    }

    @include breakpoint($bp2){
        width: 300px;
        height: 450px;
        &__title{
            margin-bottom: 32px;
        }
    }
}


