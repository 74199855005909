$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.tab {

  position: relative;
  height: 48px;

  body.rtl-lang & {
    direction: rtl;
  }

  div {
    padding: 0 20px;

    white-space: nowrap;
    overflow: auto;
    text-align: center;

    @include without-scrollbar();
  }

  a {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none !important;
    color: #A7AFBE;

    padding-bottom: 3px;
    border-bottom: 2px solid #2D394C;

    display: inline-block;
    margin-top: 12px;
    margin-bottom: 12px;

    &.is-active {
      color: #FFFFFF;
      border-color: #CC0034;
    }

    @include hover(){
      color: #FFFFFF;
    }

    & + a {
      margin-inline-start: 12px;
    }
  }

 @include shadow-before-after();
}