$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
/* .bonus-new__casino-tape */
.bonus-new {
  max-width: 280px;

  &__header {
    height: 123px;
    justify-content: center;

    background:
      linear-gradient(white 0%, white 100%) 20px 20px no-repeat,
      linear-gradient(white 0%, white 100%) 20px 20px no-repeat,
      linear-gradient(white 0%, white 100%) calc(100% - 20px) calc(100% - 20px) no-repeat,
      linear-gradient(white 0%, white 100%) calc(100% - 20px) calc(100% - 20px) no-repeat,
      url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNpcmNsZSBjeD0iMTAiIGN5PSIxMCIgcj0iMSIgZmlsbD0iI0VGRkIzNyIgZmlsbC1vcGFjaXR5PSIuMzUiLz48L3N2Zz4=') center repeat,
      linear-gradient(#CC0034 0%, #CC0034 100%) center repeat;
    background-size: 4px calc(100% - 88px), calc(100% - 40px) 4px, 4px calc(100% - 40px), calc(100% - 80px) 4px, auto, contain;
    padding: 24px;

    @include text(16px, 19px, null, #fff );
    text-align: center;
  }

  &__amount{
    margin-bottom: 4px;
    max-width: 100%;
    max-height: 38px;

    &.is-big{
      @include text(20px, 23px, 700);
    }
  }

  &__name{
    max-height: 38px;
  }

  &__btn-cancel{
    width: 72px;
    @include text(16px, 19px, null, #fff );
    margin-left: 8px;
    text-align: center;

    @include ellipsis;
  }

  & &__last-row{
    margin-bottom: 4px;
    border-bottom: none;
  }


  @include breakpoint($bp1){
    & &__btn-stroke{
      width: 138px;
      min-width: 138px;
    }
    &__btn-cancel{
      width: 60px;
    }
  }

  @include breakpoint($bp2){
    & &__btn-stroke{
      width: 160px;
    }
    &__btn-cancel{
      width: 72px;
    }
  }
}