$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.banner-popup-head {

  background: #CC0034;

  width: 100%;
  height: 80px;

  position: relative;
  display: flex;
  align-items: center;
  padding-left: 20px;

  overflow: hidden;

  @include breakpoint( $bp1 ){
    height: 141px;
    padding-left: 31px;
  }
  @include breakpoint( $bp2 ){
    height: 200px;
    padding-left: 42px;
  }
  @include breakpoint( $bp3 ){
    height: 353px;
    padding-left: 55px;
  }

  &__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    z-index: 9;

    width: 55%;
    max-height: 57px;
    overflow: hidden;

    white-space: break-spaces;

    @include breakpoint( $bp1 ){
      font-size: 24px;
      line-height: 28px;
      max-height: 84px;
    }

    @include breakpoint( $bp2 ){
      font-size: 32px;
      line-height: 37px;
      max-height: 111px;
    }

    @include breakpoint( $bp3 ){
      font-size: 58px;
      line-height: 68px;
      max-height: 204px;
    }
  }

  &__img {
    position: absolute;
    right: 0;
    top: 0;
    width: 160px;
    height: 100%;
    z-index: 0;
    background-size: cover;
    background-position: center -10px;
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: -1px;
      top: 0;
      background: linear-gradient(0deg, #cc0034 0%, rgba(188,31,56, 0) 100%);
      background: -webkit-linear-gradient(0deg, #cc0034 0%, rgba(188,31,56, 0) 100%);
    }

    @include breakpoint( $bp1 ){
      width: 270px;
      background-position: center -20px;
    }
    @include breakpoint( $bp2 ){
      width: 382px;
    }
    @include breakpoint( $bp3 ){
      width: 511px;
    }
  }

}
