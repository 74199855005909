$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.game-box2 {

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(auto, 1fr);

  @include breakpoint( $bp1 ){
    grid-gap: 8px;
    grid-template-columns: repeat(2, 1fr);
  }
  @include breakpoint( $bp2 ){
    grid-template-columns: repeat(3, 1fr);
  }
  @include breakpoint( $bp3 ){
    grid-template-columns: repeat(4, 1fr);
  }

}