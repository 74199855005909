$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.card-guide {
  margin: 12px 20px 40px;

  @include breakpoint($bp2) {
    margin: 20px auto 40px;
  }

  &__section {
    margin-bottom: 24px;
    margin: 0 20px 28px 20px;
    padding-bottom: 8px;
    background-color: #162334;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    @include breakpoint($bp2) {
      min-height: 335px;
      margin: 0 auto 20px auto;
      max-width: 728px;
      padding-inline-start: 230px;
      padding-block-start: 40px;
      padding-block-end: 28px;
    }

    body.rtl-lang & {
      direction: rtl
    }
  }

  &__title {
    @include text(24px, 28px, bold);
    width: 100%;
    padding-block: 5px;
    padding-inline-end: 95px;
    padding-inline-start: 16px;
    margin-bottom: 20px;
    background-image: var(--icon-pattern-dots-yellow);
    background-color: $red;
    min-height: 54px;
    max-height: 54px;
    overflow: hidden;
    display: flex;
    align-items: center;

    @include breakpoint($bp2) {
      @include  text(28px, 33px);
      padding-inline-end: 0;
      padding-inline-start: 40px;
      padding-block: 0;
      background: none;
      justify-content: flex-start;
      min-height: initial;
      max-height: initial;
      margin-bottom: 16px;
    }
  }

  &__wrap {
    padding-block: 20px;
    padding-inline: 15px;
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    min-height: 54px;

    @include breakpoint($bp2) {
      padding-block: 28px;
      padding-inline-start: 220px;
      padding-inline-end: 20px;
      min-height: 100%;
      width: 230px;
    }

    &::before {
      content: "";
      background-size: 68px 68px;
      background-repeat: no-repeat;
      position: absolute;
      width: 68px;
      height: 68px;
      inset-inline-end: 28px;
      bottom: 0;

      @include breakpoint($bp2) {
        z-index: 1;
        width: 120px;
        height: 120px;
        inset-inline-start: 50px;
        inset-inline-end: initial;
        top: 50px;
        background: url($cdn + '/rabona/cup-page/m_cup-banner.png');
        background-size: 120px 120px;
        background-repeat: no-repeat;
        transform: rotate(-15deg);

        body.rtl-lang & {
          rotate: 15deg;
        }
      }
    }

    &::after {
      @include breakpoint($bp2) {
        content: "";
        position: absolute;
        width: 230px;
        flex-shrink: 0;
        inset-inline-start: 0;
        top: 0;
        height: 100%;
        background-image: var(--icon-pattern-dots-yellow), linear-gradient(-59.5deg, rgba(0, 0, 0, 0) 50%, #CC0034 40%, #CC0034 100%);
        background-size: 21px , 200px 335px;
        background-repeat: repeat , no-repeat;
      }

      body.rtl-lang & {
        scale: -1 1;
      }
    }

    &.is-points::before {
      background-image: var(--icon-points-yellow);
    }
    &.is-challenges::before {
      background-image: var(--icon-collection-challenges);
    }
    &.is-cards::before {
      background-image: var(--icon-collections-yellow);
    }
    &.is-cup::before {
      background-image: var(--icon-cup);
    }
  }

  &__item {
    width: 100%;
    padding-inline-start: 40px;
    padding-inline-end: 16px;
    @include text(16px, 150%);
    background-image: linear-gradient($yellow, $yellow), linear-gradient($yellow, $yellow);
    background-size: 12px 2px, 2px 12px;
    background-repeat: no-repeat, no-repeat;
    background-position: 17px 12px, 22px 7px;
    margin-bottom: 12px;

    @include breakpoint($bp2) {
      margin-bottom: 16px;
      padding-inline-start: 82px;
      background-position: 46px 12px, 51px 7px;
    }
    
    body.rtl-lang & {
      background-position: calc(100% - 17px) 12px, calc(100% - 22px) 7px;
      
      @include breakpoint($bp2) {
        background-position: calc(100% - 46px) 12px, calc(100% - 51px) 7px;
      }
    }
  }
  
  &__btn {
    margin-inline: auto;
    margin-block: 0 12px;
    text-align: center;
    max-width: none;
    min-width: auto;
    padding: 0 32px;

    @include breakpoint($bp2) {
      margin-inline-start: 46px;
      display: inline-block;
    }
  }
}


