$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.cashbox {
  &__section {
    width: 280px;
    margin: 0 auto;

    @include breakpoint($bp1) {
      width: 392px;
    }

    & + & {
      border-top: 1px solid $lightGrey;
    }

    &.no-payments {
      margin-top: 80px;
      border-top: none;

      @include breakpoint($bp1) {
        margin-top: 90px;
      }
    }
  }

  &__btn {
    margin-top: 8px;
    margin-bottom: 12px;

    &[disabled] {
      background-color: $grey;

      @include hover() {
        background-color: $grey;
      }
    }

    .no-payments & {
      margin-top: 20px;
    }

    span {
      body.rtl-lang & {
        display: inline-block;
      }
    }
  }

  &__show-more {
    position: relative;
    padding-inline-start: 20px;
    margin: auto;
    background-size: 18px;
    filter: brightness(0.2);

    body.rtl-lang & {
      background-position: right 0 center;
    }
  }

  &__icon-link {
    display: inline-block;
    @include text(14px, 48px, normal, $grey);
    padding-inline-start: 20px;
    background-size: 16px;
    background-position: 0 calc(50% - 2px);
    @include hover() {
      opacity: 0.8;
      text-decoration: none;
    }

    body.rtl-lang & {
      background-position: right 0 center;
    }
  }

  &__link {
    @include text(14px, 16px, 700, $darkGrey);
    letter-spacing: 0.01em;
    text-decoration-line: underline;
    text-transform: uppercase;
    margin-top: 24px;
  }
}
