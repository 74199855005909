$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.balance-filter-description {
  display: flex;
  justify-content: center;
  border-bottom: none;

  font-weight: bold;
  @include text(14px, 16px);
  letter-spacing: 0.02em;
  color: $grey;
  border-top: 1px solid $blue;
  padding-top: 20px;

  &__item {
    margin: 0 10px;
    display: flex;
    align-items: center;
  }

  &__color {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-inline-end: 4px;
    &.is-red {
      background-color: $red;
    }
    &.is-green {
      background-color: #26C000;
    }
  }
}