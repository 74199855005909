$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.profile-data {
  background-color: #192333;
  padding: 0 20px;

  &__row{
    width: 100%;  
    display: flex;
    align-items: center;
    min-height: 55px;
    padding: 8px 0;
    border-bottom: 1px solid $blue;
    &:last-child{
      border-bottom: none;
    }
  }

  &__key{
      margin-right: 8px;
      width: 36.3%;
      flex-shrink: 0;
  
      @include ellipsis;
      @include text(12px, 14px, null, $grey);
      text-transform: uppercase;
  }

  &__value{
      flex-grow: 1;
      text-align: end;
      @include text(16px, 1.2, null, #fff);
      word-break: break-word;
      overflow: hidden;
  }

  a.profile-data__value {
    color: $yellow;
  }

}