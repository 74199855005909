$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.vip-card {
  margin-block-start: 30px;
  margin-inline: 4px;

  &__item {
    border: 1px solid #293241;
    padding-block-start: 68px;
    padding-block-end: 20px;
    padding-inline: 20px;
    background-repeat: no-repeat;
    background-position: 20px 20px;
    background-size: 34px;
    margin-bottom: 20px;
    
    body.rtl-lang & {
      direction: rtl;
      background-position: calc(100% - 20px) 20px;
    }

    &:nth-child(1) {
      background-image: url($cdn + '/rabona/vip/icon-vip/manager.svg')
    }
    &:nth-child(2) {
      background-image: url($cdn + '/rabona/vip/icon-vip/finance.svg')
    }
    &:nth-child(3) {
      background-image: url($cdn + '/rabona/vip/icon-vip/bonuses.svg')
    }

    &:nth-child(4) {
      background-image: url($cdn + '/rabona/vip/icon-vip/nolimits.svg')
    }

  }

  &__title {
    @include text(20px, 120%, bold);
    text-transform: uppercase;
  }

  &__text {
    margin-top: 8px;
    @include text(12px, 150%, null, #A5AFC0);
  }

  @include breakpoint($bp1) {
    margin-top: 48px;
    margin-inline: 10px;
    &__item {
      padding-block-start: 20px;
      padding-inline-start: 80px;
      background-position: 27px 27px;
      height: 158px;
      overflow: hidden;
      
      body.rtl-lang & {
        background-position: calc(100% - 27px) 27px;
      }
    }
    &__title {
      line-height: 40px;
    }
    &__text {
      margin-top: 6px;
    }
  }
  @include breakpoint($bp2) {
    margin-top: 52px;

  }
  @include breakpoint($bp3) {
    margin-inline: 8px;

    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat( 2 , 1fr );

    &__item {
      height: 176px;
      margin-bottom: 0;
    }
  }
}