$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;

.bonus-tape{
    position: absolute;
    top: 6px;
    left: -13px;
    background-color: $yellow;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);

    text-transform: uppercase;
    font-size: 8px;
    font-weight: 700;
    line-height: 9px;
    text-align: center;
    color: $black;

    clip-path: polygon(25% 0%, 75% 0%, 100% 100%, 0% 100%);
    width: 51px;
    height: 13px;
    padding: 2px ;

    transform: rotate(-45deg);

    body.rtl-lang & {
        transform: rotate(45deg);
        left: auto;
        right: -13px;
        text-align: center;
    }
}
