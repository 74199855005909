$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.footer-nav {
  display: grid;
  gap: 2px;

  &__title {
    position: relative;
    padding-block: 16px;
    padding-inline: 12px 32px;

    @include text(16px, 19px, 400, $white);
    min-height: 52px;
    border-bottom: 1px solid #273242;
    background-color: #162334;
    transition: 0.2s color;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      inset-inline-end: 10px;
      width: 16px;
      height: 16px;
      background-image: var(--icon-arrow-down);
      background-repeat: no-repeat;
      background-size: contain;
      transform: translateY(-50%);
      filter: brightness(2);
      transition: 0.2s all;
    }

    &.is-active {
      color: $yellow;

      &::after {
        filter: brightness(0) saturate(100%) invert(97%) sepia(27%) saturate(5396%) hue-rotate(9deg) brightness(107%)
          contrast(105%);
        transform: translateY(-50%) rotate(180deg);
      }

      & + .footer-nav__list {
        max-height: unset;
        opacity: 1;
        padding: 12px;

        .footer-nav__list-item {
          pointer-events: initial;
        }
      }
    }

    &.is-link {
      display: block;

      &::after {
        display: none;
      }
    }
  }

  &__list {
    padding: 0 12px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    max-height: 0;
    opacity: 0;
    transition: 0.15s ease all;
    border-bottom: 1px solid #273242;

    body.rtl-lang & {
      direction: rtl;
    }
  }

  &__list-item {
    min-height: 20px;
    width: 100%;
    text-transform: capitalize;
    pointer-events: none;
    @include ellipsis;
    @include text(16px, 19px, 400, #a5afc0);
    @include hover() {
      color: $white;
      text-decoration: underline;
    }

    &.active {
      color: $yellow;
    }
  }
}
