$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.achievement-progress {
  padding: 40px 20px;
  margin-bottom: 40px;
  border: 1px solid #394557;

  &.is-migration {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  &__title {
    text-transform: uppercase;
  }
  &__top {
    font-size: 24px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    gap: 8px;
  }
  &__text {
    display: flex;
    align-items: center;
    color: $yellow;
    gap: 4px;

    direction: ltr;
  }
  &__icon {
    border: 2px solid;
    border-radius: 50%;
    text-align: center;
    font-weight: 400;
    gap: 4px;
    &.is-yellow {
      width: 28px;
      height: 28px;
      font-size: 22px;
      color: $yellow;
      border-color: $yellow;
    }
    &.is-white {
      width: 16px;
      height: 16px;
      font-size: 12px;
      border-width: 1px;
      margin-right: 2px;
      margin-left: 4px;
    }
  }
  &__info {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 3px;
  }
  &__bottom {
    display: flex;
    align-items: center;
    gap: 9px;
    font-size: 16px;
    font-weight: 500;
    width: calc(100% - 7px);
    margin-inline-start: 7px;
  }
  &__line {
    height: 2px;
    flex-grow: 1;
    background-color: #576477;

    position: relative;

    body.rtl-lang & {
      scale: -1 1;
    }

    &::before {
      content: "";
      left: -5px;
      width: 7px;
      height: 7px;
      position: absolute;
      background-color: #ECFD00;
      transform: rotate(-45deg) translateY(-50%);
    }
    &::after {
      content: "";
      right: -3px;
      width: 7px;
      height: 7px;
      position: absolute;
      background-color: #576477;
      transform: rotate(-45deg) translateY(-50%);
    }
  }
  &__price {
    flex-shrink: 0;
  }
  &__migration {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 16px;
  }
  &__btn b {
    margin: 0 3px;
  }
}

.achievement-progress__bottom.is-complete {
  .achievement-progress__line::after {
    background-color: $yellow;
  }
}

.rtl-lang .achievement-progress__icon.is-white {
  order: 1;
}