$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.btn {

    color: #080F21;
    font-weight: bold;
    font-size: 14px;
    line-height: 40px;
    text-transform: uppercase;
    min-width: 160px;
    padding: 0 20px;
    text-align: center;

    background: #EFFB37;
    @include ellipsis;
    @include hover(){
        background: #7CF74C;
        text-decoration: none;
    }
    &.is-full{
        width: 100%;
    }
    &_full{
        width: 100%;
        min-width: 0;
    }
    &.is-disabled{
        background: #a5afc0;
        pointer-events: none;
    }
    &.is-closure-btn{
        font-weight: bold;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: #080F21;
        height: 56px;
        padding-left: 8px;
        padding-right: 8px;
    }
    &--font11 {
        font-size: 11px;
    }
    &--font13 {
        font-size: 13px;
    }
    &--font18 {
        font-size: 18px;
    }

    body.rtl-lang &{
        text-align: center;
        font-weight: 900;
        font-family: system-ui;
    }
}
