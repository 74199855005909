$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.home-subheader {
    position: relative;
    background: $black;
    height: 48px;

    body.rtl-lang & {
        direction: rtl;
    }

    div {
        padding: 0 5px 0 15px; 
        white-space: nowrap;
        overflow: auto;
        text-align: center;
        @include without-scrollbar();
    }

    &__item {
        height: 48px;
        display: inline-block;
        text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.4), 1px 2px 0px #1E2737;
        position: relative;
        font-style: italic;
        @include text(16px, 48px, null, $grey);
        text-align: center;
        text-transform: uppercase;
        padding: 0 18px;

        @include hover() {
            text-decoration: none;
            color: $yellow;
        }

        &::before {  left: 0; }
        &::before, &:last-child::after {
            content: '';
            position: absolute;
            top: -2px;
            width: 1px;
            height: calc(100% + 4px);
            background: $lightBlue;
            transform: rotate(23deg);
        }

        &:last-child::after { right: 0;}
    }
}
