$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.home-game-category {
  position: relative;
  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 12px;
    z-index: 10;
  }
  &::after {
    right: 0;
    background: linear-gradient(270deg, #0c1221 0%, rgba(12, 18, 33, 0) 100%);
  }
  &::before {
    left: 0;
    background: linear-gradient(90deg, #0c1221 0%, rgba(12, 18, 33, 0) 100%);
  }
  &__wrap {
    display: flex;
    overflow-x: auto;
    padding: 0 0 12px 20px;
  }
  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    width: 145px;
    min-height: 230px;
    padding: 28px 0;
    flex-shrink: 0;
    overflow: hidden;
    &:last-child {
      margin-right: 20px;
    }
    & + & {
      margin-left: 16px;
    }

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 58px;
      bottom: -30px;
      right: 0;
      mask: url('/img/vip-pattern3.svg');
      background-image: linear-gradient(to top, #a5afc0, rgba(165, 175, 192, 0));
      z-index: 10;
    }
    &::after {
      content: '';
      position: absolute;
      height: 100px;
      width: 100%;
      background-color: #162334;
      border-top: 2px solid #bc1f39;
      bottom: -26px;
      transform: skewY(-12deg);
    }
  }

  &__item-image {
    position: absolute;
    inset: 0;
    background-position: top center;
    background-size: 100%;
    background-repeat: no-repeat;
  }

  &__content {
    position: absolute;
    top: 138px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
  }
  &__icon {
    position: relative;
    width: 34px;
    height: 34px;
    margin-bottom: 18px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    background-color: #273242;
    background-size: 22px;
    background-repeat: no-repeat;
    background-position: center;

    &::after {
      content: '';
      position: absolute;
      width: 54px;
      height: 32px;
      bottom: -8px;
      left: -12px;
      background-image: var(--icon-round-leaves);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      filter: invert(78%) sepia(11%) saturate(398%) hue-rotate(179deg) brightness(90%) contrast(85%);
    }
  }
  &__title {
    @include text(14px, 14px, 700, #ffffff);
    text-transform: uppercase;
  }
}
