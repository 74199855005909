$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.game-box-title {
  display: flex;
  align-items: center;

  body.rtl-lang & {
    direction: rtl;
  }

  .title {
    margin-inline-start: 20px;
    margin-inline-end: auto;
  }

  span {
      margin-inline: 10px 3px;
      display: inline-block;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #a5afc0;
  }

  &::after {
    width: 20px;
    height: 20px;
    filter: $filterGrey;
    transition: 0.3s ease transform;

    body.rtl-lang & {
      scale: -1 1;
    }
  }

  @include hover() {
    text-decoration: none;
    &::after {
      opacity: 0.8;
      transform: translateX(8px);
      text-decoration: none;
    }
  }
}
