$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.layout-tournaments-list {
    flex-grow: 1;
    padding-top: 12px;

    &__title {
        font-size: 28px;
        text-transform: uppercase;
        text-align: center;
        line-height: 33px;
        padding-top: 16px;
        margin-bottom: 4px;
    }

    &__wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 8px;
        min-height: 476px;
    }

    @include breakpoint($bp1) {
        &__wrap {
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            margin: 0 10px;
        }
    }

    @include breakpoint($bp2) {
        &__wrap {
            margin: 0 12px;
        }
    }

    @include breakpoint($bp3) {
        &__wrap {
            max-width: 992px;
            margin: 0 auto 4px;
        }
    }

}