$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.cup-info {
  margin: 40px 0 40px;
  border: 1px solid #293241;
  padding: 37px 25px 25px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background-image: url($cdn + '/rabona/cup-page/m_icon-info.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 38px;
    height: 38px;
    top: -19px;
    left: 50%;
    transform: translate(-50%);

    border-radius: 50%;
    border: 1px solid $yellow;
  }
  &__title {
    @include text(24px, 120%);
    text-transform: uppercase;
  }
  &__text {
    @include text(16px, 150%, null, #A5AFC0);
    margin-top: 13px;
  }

  @include breakpoint($bp2) {
    padding: 30px 26px 30px 142px;
    &::before {
      width: 62px;
      height: 62px;
      top: 50%;
      transform: translateY(-50%);
      left: 40px;
    }
    &__title {
      font-size: 28px;
    }
    &__text {
      margin-top: 8px;
    }
  }

}