$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.cash-crab-link {
  & span {
    position: relative;

    &.has-icon-crab {
      &::after {
        content: '';
        display: none;
      }
    }
    &.has-icon-crab-free {
      &::after {
        content: attr( data-value-crab-attempts );
        width: 14px;
        height: 14px;
        position: absolute;
        background: #fed22a;
        border-radius: 50%;
        color: #0e0e0e;
        line-height: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 500;
        margin: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}