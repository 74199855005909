$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.challenge-reward {
  display: inline-flex;
  align-items: center;
  position: relative;
  background-color: #BC1F39;
  height: 24px;
  @include text(20px, 28px, 700, $white);
  padding-left: 20px;
  padding-right: 4px;
  margin-left: 14px;

  &.is-finished::before {
    background-image: var(--icon-points-finished);
  }

  &::before, &::after {
    content: '';
    position: absolute;
    display: block;
  }

  &::before {
    background-image: var(--icon-points-active);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 28px;
    height: 28px;
    position: absolute;
    left: -14px;
  }

  &::after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 24px 10px 0 0;
    border-color: #BC1F39 transparent transparent transparent;
    right: -10px;
  }

  &.is-sport {
    margin-top: 16px;
  }

  &.is-finished {
    background-color: #576477;
    color: #162334;

    &::after {
      border-color: #576477 transparent transparent transparent;
    }
  }
}