$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.close-registration {
  margin: -80px 0 -20px;

  .popup-body {
    animation-name: none !important;

    .popup-close {
      top: 50px;
    }
  }
}
