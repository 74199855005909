$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.cabinet-card {
    @include cabinet-bg;
    border: 0.60314px solid rgba(167, 175, 190, 0.2);
    padding: 7px 16px;
    background-color: $red;

    position: relative;
    overflow: hidden;

    display: flex;
    height: 48px;
    justify-content: space-between;
    align-items: center;
    @include text(14px, 16px);
    transition: 0.2s ease opacity;

    @include hover() {
        opacity: 0.9;
    }

    & > *:not(.cabinet-card__team-logo) {
        z-index: 10;
    }

    &__collection {
        font-size: 12px;
        margin-bottom: 4px;
    }

    &__title {
        flex-grow: 1;
        @include ellipsis;
        display: flex;
        flex-direction: column;

        &.is-points {
            span > span {
                display: inline-block;
                color:  #FFACC1;
                @include text(10px);
                margin-inline-start: 4px;
                text-transform: lowercase;

                &::before {
                    content: '|  ';
                    @include text(15px, 16px);
                }

                &::after {
                    content: '';
                    transform: translateY(1px);
                    display: inline-block;
                    @include text(14px, 16px);
                    margin-inline-start: 3px;
                }
            }
        }

        &.has-icon {
            display: flex;
            flex-direction: row;
            align-items: center;

            span {
                max-width: calc(100% - 20px);
                @include ellipsis;
            }

            &::after {
                content: '.';
                color: transparent;
                flex-shrink: 0;
                width: 16px;
                height: 16px;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                margin-inline-start: 4px;
            }
        }
    }

    body.rtl-lang &__title {
        &:after {
            transform: rotate(180deg);
        }
    }

    &__value {
        min-width: 40px;
        flex-shrink: 0;
        margin-inline-start: 6px;
        @include text(16px, 32px, 700);
        text-align: right;

        &.has-icon {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: flex-end;

            &::after{
                content: "";
                width: 16px;
                height: 16px;
                margin-inline-end: 4px;
                background-size: 100%;
            }

            body.rtl-lang & {
                direction: rtl;
            }
        }
    }

    &__team-logo {
        position: absolute;
        z-index: 1;
        inset-inline-start: -20px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 40px;
        height: 40px;
        background-size: contain;
        background-position: center;
        opacity: 0.5;
    }
}
