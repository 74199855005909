$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.card-label {
  position: absolute;
  top: -4px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;

  &__text {
    clip-path: polygon(0 0, 100% 0, calc(100% - 9px) 100%, 9px 100%);
    white-space: nowrap;
    padding: 4.5px 15px;
    min-height: 21px;
    background-color: #576477;
    text-align: center;
    font-size: 10.3px;
    line-height: 12px;
    font-weight: 700;
    color: $white;
  }

  &__shadow {
    clip-path: polygon(0 0, 100% 0, calc(100% - 13px) 100%, 13px 100%);
    position: absolute;
    height: 100%;
    width: calc(100% + 2px);
    background-color: rgba(0,0,0, .25);
    top: 4px;
    left: -1px;
    z-index: -1;
  }
}