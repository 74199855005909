$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.cabinet-balance {
    @include cabinet-bg;
    background-color: $black;
    border: 1px solid rgba(12, 18, 33, 0.5);

    @include hover() {
        opacity: 0.9;
    }

    &.has-sport-label {
        position: relative;
    }

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include text(13px, 15px, 400, $grey);

        &:not(:first-child) {
            margin-top: 8px;
        }

        &.is-bold {
            font-weight: 700;
            color: $white;

            .cabinet-balance__value {
                font-size: 16px;
            }
        }
    }

    &__title {
        flex-grow: 1;
        margin-inline-end: 4px;
        
        &:not(.cabinet-balance__title-crab) {
            @include ellipsis;
        }
    }

    &__value {
        white-space: nowrap;

        &.has-icon {
            display: flex;
            align-items: center;

            &::after {
                content: "";
                width: 16px;
                height: 16px;
                margin-inline-start: 4px;
                background-size: 100%;
            }

            &.icon-money__after {
                body.rtl-lang & {
                    &::after {
                        content: none;
                    }
                    &::before {
                        content: "";
                        width: 16px;
                        height: 16px;
                        margin-right: 4px;
                        background-size: 100%;
                        background-image: var(--icon-money);
                    }
                }
            }
            &.icon-promo-wager__after {
                body.rtl-lang & {
                    &::before {
                        content: 'الرهان لا يزال قائما';
                        line-height: 0;
                        font-size: 0;
                        display: contents;
                        pointer-events: none;
                    }
                }
            }
        }
    }

    &__team {
        font-weight: 700;
        display: flex;
        align-items: flex-end;

        span {
            max-width: calc(100% - 20px);
            @include ellipsis;
        }

        .star {
            flex-shrink: 0;

            @include rate-star;
        }
    }
}