$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.cup-details {

  &__title {
    @include  text(24px, 28px, bold);
    padding: 14px 85px 14px 16px;
    padding-block: 14px;
    padding-inline: 16px 85px;
    background-image: var(--icon-pattern-dots-yellow);
    background-color: $red;
  }

  &__wrap {
    background-color: #162334;
    padding: 20px 16px;
    position: relative;

    &::before {
      content: "";
      background: url($cdn + '/rabona/cup-page/m_cup-banner.png');
      background-size: 84px 117px;
      background-repeat: no-repeat;
      position: absolute;
      width: 84px;
      height: 117px;
      inset-inline-end: 5px;
      top: -95px;
    }
  }

  &__item {
    padding-inline-start: 23px;
    @include text(16px, 150%);
    background-image: linear-gradient($yellow, $yellow), linear-gradient($yellow, $yellow);
    background-size: 12px 2px, 2px 12px;
    background-repeat: no-repeat, no-repeat;
    background-position: 0 10px, 5px 5px;

    &:not(:last-of-type) {
      margin-bottom: 14px;
    }

    body.rtl-lang & {
      background-position: 100% 10px, calc(100% - 5px) 5px;
    }
  }

  &__btn {
    margin: 20px auto 0;
    text-align: center;
    max-width: 248px;
  }

  @include breakpoint($bp2) {
    &__wrap {
      padding: 40px 18px 40px 217px;

      &::before {
        z-index: 1;
        width: 177px;
        height: 247px;
        left: 10px;
        top: -28px;
        background: url($cdn + '/rabona/cup-page/m_cup-banner.png');
        background-size: 177px 247px;
        background-repeat: no-repeat;
      }

      &::after {
        content: "";
        position: absolute;
        width: 202px;
        flex-shrink: 0;
        left: 0;
        top: 0;
        height: 100%;
        background-image: var(--icon-pattern-dots-yellow), linear-gradient(-59.5deg, rgba(0, 0, 0, 0) 50%, #CC0034 40%, #CC0034 100%);
        background-size: 21px , 202px 348px;
        background-repeat: repeat , no-repeat;
      }
    }
    &__title {
      @include  text(36px, 42px, bold);
      padding: 0 0 25px;
      background: none;
      text-align: center;
    }
    &__item {
      font-size: 18px;
      background-size: 18px 2px , 2px 18px;
      background-position: 0 13px , 8px 5px;
      padding-left: 29px;

      &:not(:last-of-type) {
        margin-bottom: 42px;
      }
    }
    &__btn {
      margin-top: 40px;
      max-width: 318px;
    }
  }

  @include breakpoint($bp3) {
    .cup-details__wrap {
      padding: 40px 34px 40px 232px;
    }
  }

}