$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.popup-info {
  &__cards {
    overflow: hidden;
    padding-bottom: 16px;
    border-bottom: 2px solid red;
    margin-bottom: 20px;

    body.rtl-lang & {
      direction: rtl;
    }
  }
  &__card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    padding-inline-start: 38px;
    padding-inline-end: 16px;
    @include text(16px, 44px);
    //@include ellipsis;

    line-height: 16px;
    color: $white;
    margin-bottom: 4px;
    background-color: $black;
    background-repeat: no-repeat;
    background-position: 10px center;
    background-size: 20px;

    & span {
      @include ellipsis;
    }

    body.rtl-lang & {
      background-position: calc(100% - 10px) center;
    }

  }
  &__value {
    padding-inline-start: 10px;
    flex-shrink: 0;
  }
  &__text {
    & table {
      margin-bottom: 12px;
    }
    & p {
      padding-bottom: 12px;
    }
    & tr {
      height: 48px;
      background: $black;
      border-bottom: 4px solid #192333;
      color: $white;


    }
    & td {
      padding: 0 12px;

      &:nth-child(2) {
        text-align: right;
      }

    }
    & ul {
      padding-bottom: 12px;
    }

    &.popup-text p{
      text-align: start;

      body.rtl-lang & {
        text-align: start;
      }
    }
  }
}
