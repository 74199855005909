$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.game-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(auto, 1fr);
  grid-gap: 8px;
  padding: 8px 20px 20px;

  @include breakpoint( $bp1 ){
    grid-template-columns: repeat(4, 1fr);
  }
  @include breakpoint( $bp2 ){
    grid-template-columns: repeat(5, 1fr);
  }
  @include breakpoint( $bp3 ){
    grid-template-columns: repeat(6, 1fr);
  }

  body.rtl-lang & {
    direction: rtl;
  }

}

.empty-category-caption {
  &__wrapper {
    color: $white;
    margin: 8px 20px 40px;
  }
  &__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    margin-bottom: 6px;
  }
  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  &__img {
    position: relative;
    width: 290px;
    height: 120px;
    background-repeat: no-repeat;
    margin-top: 10px;
    margin-inline-end: auto;

    body.rtl-lang & {
      direction: rtl;
      scale: -1 1;
    }

    &::after {
      content: "";
      position: absolute;
      inset: 0;
      background: linear-gradient(180deg, rgba(12, 18, 33, 0) 0%, $black 100%);
    }
  }
}