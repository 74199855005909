$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.info{
    padding-top: 8px;
    width: 100%;

    body.rtl-lang & {
        direction: rtl;
    }

    &.no-accordion {
        padding-top: 28px;
    }

    h1{
        display: none;
    }

    & > div > div p{
        max-width: 820px;
        @include text(16px, 24px, 400, $grey);
        margin: 0 auto 8px;
        padding: 0 20px;
        body.rtl-lang & {
            direction: rtl;
            text-align: right;
        }
    }

    .info_sitemap__title {
        position: relative;
        background-image: linear-gradient(0deg, $red, $red);
        background-size: 100% 2px;
        background-repeat: no-repeat;
        background-position: 0 0;

        @include text(16px, 19px,700, $grey);
        padding: 24px 20px 12px;
        text-transform: uppercase;
        width: calc(100% + 2px);
        top: -1px;
        inset-inline-start: -1px;
        text-align: center;

        body.rtl-lang & {
            text-align: center;
        }
    }

    &__section, section{
        display: flex;
        flex-direction: column;
        background-color: #192333;
        max-width: 820px;
        margin: 0 auto 20px;
        border: 1px solid #293241;
        p, ol, ul, h3, h4, h5, h6, div{
            @include text(16px, 24px, 400, $grey);
            body.rtl-lang & {
                text-align: right;
            }
        }
    }

    ul,
    ol {
        list-style-type: disc;
        list-style-position: inside;
        li:not(:last-child){
            margin-bottom: 12px;
        }
    }

    li {
        display: block;
        position: relative;
        padding-inline-start: 14px;

        &::before{
            width: 4px;
            height: 4px;
            content: '';
            background-color: #a7afbe;
            border-radius: 50%;
            position: absolute;
            inset-inline-start: 0;
            top: 10px;
        }
        body.rtl-lang & {
            flex-direction: row-reverse;
        }
    }

    table{
        overflow: auto;
        display: block;
        -webkit-overflow-scrolling: touch;

        thead {
            th,
            td {
                @include text(14px, 19px, 400, $grey );
            }
        }

        tbody {
            tr td {
                @include text(16px, 27px, 400, #fff);
            }
        }

        td,
        th {
            border: 1px solid grey;
            text-align: center;
            padding: 5px 8px;
            overflow: hidden;
        }

        tr td:not(:first-child){
            min-width: 168px;
        }
    }

    .change-cookies-link {
        display: block;
        margin: 0 auto 20px;
        padding: 0 24px;
        text-decoration: underline;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        max-width: 820px;

        @include breakpoint($bp3){
            padding: 0;
            margin: 0 auto;
        }
    }
}


