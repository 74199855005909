$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.seasons-christmas-link {
  &::before {
    content: '';
    inset: 0;
    background-color: #ffffff;
    position: absolute;
    -webkit-mask: url(./img/snowflake.svg) right / 25px 100% no-repeat;
    mask: url(./img/snowflake.svg) right / 25px 100% no-repeat;
  }

  &::after {
    content: '';
    inset: 0;
    background-color: #ffffff;
    position: absolute;
    -webkit-mask: url(./img/snow.svg) right / 25px 100% no-repeat;
    mask: url(./img/snow.svg) right / 25px 100% no-repeat;
    filter: none !important;
    opacity: 0.6;
  }

  &.active {
    &::before {
      .is-sport-tab:not(.is-casino-tab) & {
        background-color: #576477;
      }
    }
  }

  span {
    position: relative;
    &::before {
      content: '';
      width: 18px;
      height: 18px;
      display: inline-block;
      background-image: url('/img/gift.svg');
      margin-right: 6px;
      margin-bottom: -2px;
    }
  }
}
