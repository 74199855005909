$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.vip-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  
  body.rtl-lang & {
    direction: rtl;
  }
}