$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.no-bonus{
    width: 100%;
    padding: 8px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__title{
       @include text(20px, 23px, 700, #fff);
       text-align: center;
       text-transform: uppercase;
       margin-bottom: 20px;
    }

    &__dep{
        margin-top: 20px;
    }

    &__img{
        width: calc( 100% + 40px);
        margin: 0 -20px;
        background-size: cover;
        background-position: top center;
        body.rtl-lang & {
            scale: -1 1;
        }
        &::before{
            content: '';
            display: block;
            width: 100%;
        }
        &.has-active-img {
            background-image: url($cdn + '/rabona/profile/pic-profile-activebonus.jpg');
            &::before{
                padding-bottom:  44%;
            }
        }

        &.has-casino-img {
            background-image: url($cdn + '/rabona/profile/pic-profile-casinobonus.jpg');
            &::before{
                padding-bottom: 28%;
            }
        }

        &.has-sport-img {
            background-image: url($cdn + '/rabona/profile/pic-profile-nobonus.jpg');
            &::before{
                padding-bottom: 28%;
            }
        }
    }

    &__btn{
        width: 280px;
        margin-top: 20px;
    }
}