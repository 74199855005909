$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.form-checkbox {

  width: 100%;
  min-height: 48px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  &__input {
    margin-top: 12px;
    width: 24px;
    height: 24px;
    background: #293241;
    border-radius: 2px;
    transition: .2s all;
    &:after {
      display: none;
      content: '';
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
    }
    *:checked ~ & {
      background: #EFFB37;
      &:after {
        display: block;
      }
    }
  }
  &__text {
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.01em;
    color: #A7AFBE;
    margin: 18px 0 0 14px;
    width: calc(100% - 38px);
    min-height: 24px;
    word-break: break-word;
    text-align: left;
  }

  input {
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0;
  }

}