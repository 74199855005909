$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.payments-list {
    padding: 24px 8px 40px;

    &__grid {
        display: grid; 
        row-gap: 16px;
    }
    &__title {
        text-align: center;
        text-transform: uppercase;
        @include text(28px, 33px, 400, #FFFFFF); 
    }

    &__selects {
        display: flex; 
        justify-content: center;
        gap: 8px;
    }

    &__select.is-small {
        padding: 0;
        background: none;

        select {
            height: 100%;
        }
    }

    &__country {
        select{
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            direction: ltr;
            padding-inline: 48px 20px;
        }
        body.rtl-lang & option {
            direction: ltr;
        }
    }

    &__tabs {
        display: flex; 
        justify-content: center;
    }

    &__tab-input {
        display: none;
    } 

    &__tab-label {
        width: 144px; 
        display: inline-block;
        padding: 16px 14px 18px; 
        @include text(18px, 21px, 400);
        @include ellipsis;
        color: #A5AFC0; 
        font-style: italic;
        text-transform: uppercase;
        text-align: center;
        @include breakpoint($bp1){
            min-width: 194px;
        }

        &.is-active {
            color: #FFFFFF; 
            background: rgba(255, 255, 255, 0.045);
            clip-path: polygon(14px 0, 100% 0%, calc(100% - 14px) 100%, 0% 100%);
            border-bottom: 1px solid #BC1F39; 
        }
    }

    body.rtl-lang & {
        direction: rtl;
    }
}
