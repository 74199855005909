$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.no-accordion {
    & > div {
        & > div:not(.change-cookies-link-wrapper) {
            align-items: center;
            gap: 8px;
            display: flex;
            justify-content: center;
            margin: 0 auto;
            max-width: 820px;
            padding-bottom: 26px;

            body.rtl-lang & {
                direction: rtl;
            }

            a {
                align-items: center;
                background-color: #162334;
                border: 1px solid #273242;
                display: flex;
                font-size: 12px;
                height: 44px;
                justify-content: center;
                line-height: 14px;
                color: $wh;
                padding-block: 0;
                padding-inline: 12px 10px;

                &:hover {
                    text-decoration: none;
                }

                img {
                    display: none;
                }

                @include s {
                    width: auto;
                }

                & > div {
                    position: relative;

                    &:before {
                        position: absolute;
                        top: 0;
                        inset-inline-start: 0;

                        filter: $filterGrey;

                        content: '';
                        display: inline-block;

                        flex-shrink: 0;

                        background-position: center;
                        background-repeat: no-repeat;
                    }

                    & > div:first-child {
                        color: #A5AFC0;
                        display: flex;
                        align-items: center;
                        position: relative;

                        &:after {
                            content: '';
                            display: inline-block;
                            width: 6px;
                            height: 9px;
                            margin-inline-start: 8px;
                            filter: $filterGrey;
                            flex-shrink: 0;

                            background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9InllcyI/Pjxzdmcgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik00LjggNi41bDUuMSA1IDUuMi01IDEuMSAxLTYuMyA2LTYuMS02IDEtMXoiIGZpbGw9IiNBN0FGQkUiIHN0eWxlPSIiLz48L3N2Zz4=');
                            background-size: 22px;
                            background-position: center;
                            transform: rotate(-90deg);

                            body.rtl-lang & {
                                transform: rotate(90deg);
                            }
                        }
                    }

                    & > div:last-child {
                        cursor: default;
                    }
                }

                &:first-child {
                    order: 1;

                    & > div {
                        padding-inline-start: 28px;

                        &:before {
                            background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9InllcyI/Pjxzdmcgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0xNC44IDkuMmMtLjQgMi40IDEuNiA1LjItNi44IDYtMy45NzYgMC02LjgtMi4wMjQtNi44LTZTNC4wMjQuOCA4IC44czYuOCA0LjQyNCA2LjggOC40eiIgZmlsbD0iI2ZmZiIgZmlsbC1vcGFjaXR5PSIuMTUiIHN0eWxlPSIiLz48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTS44IDEwLjU1NWMwIC43OTguNjIzIDEuNDU2IDEuNDAyIDEuNDU2SDMuOWMuMjY1IDAgLjQ5Ny0uMjIuNDk3LS40OThWNi41NDdhLjQ5Mi40OTIgMCAwMC0uNDk3LS40ODlIMi42NTZ2LS4xNmMwLTIuNjg4IDIuNTEyLTQuODk1IDUuMzQzLTQuODk1IDIuOTI0LjA0OSA1LjM0NSAyLjIwNyA1LjM0NSA0Ljg5NnYuMTZIMTIuMWEuNDk0LjQ5NCAwIDAwLS40OTguNDg4djQuOTY2YS41LjUgMCAwMC40OTguNDk4aC4yMzZ2MS4xNWMwIC41MzgtLjQyLjk3Ny0uOTIxLjk3N0g5LjE3MmExLjI4IDEuMjggMCAwMC0xLjIwNC0uODY5Yy0uNzE0IDAtMS4yODguNi0xLjI4OCAxLjMzOHYuMDQ1YzAgLjczNC41NyAxLjM0OCAxLjI4OCAxLjM0OC41NTMgMCAxLjAxNi0uMzc3IDEuMjAyLS44NmgyLjI0NWMxLjA0OSAwIDEuODktLjkgMS44OS0xLjk4di0xLjE0OWguNDk0Yy43NzggMCAxLjQwMS0uNjU4IDEuNDAxLTEuNDU2di0zLjA1YzAtLjYxNS0uMzYtMS4xNDctLjg4Ni0xLjM2VjUuOUMxNC4zMTQgMi42MzkgMTEuNiAwIDggMFMxLjY4NiAyLjY0IDEuNjg2IDUuODk5di4yNDZDMS4xNiA2LjM1OC44IDYuODkuOCA3LjUwNXYzLjA1em03LjUwMiA0LjA5N2MwIC4xOS0uMTUyLjM0Mi0uMzI1LjM0MmEuMzM1LjMzNSAwIDAxLS4zMjUtLjM0MnYtLjA0NGMwLS4xOTEuMTUzLS4zNDIuMzI1LS4zNDIuMTczIDAgLjMyNS4xNS4zMjUuMzQxdi4wNDV6TTEuNzcgNy41MDVjMC0uMjUyLjE5NS0uNDQ0LjQzMi0uNDQ0aDEuMjI2djMuOTQ3SDIuMjAyYS40NDQuNDQ0IDAgMDEtLjQzMi0uNDUzdi0zLjA1em0xMi4wMjktLjQ0NGMuMjM2IDAgLjQzMi4xOTIuNDMyLjQ0NHYzLjA1YS40NDQuNDQ0IDAgMDEtLjQzMi40NTNoLTEuMjI3VjcuMDYxaDEuMjI3eiIgZmlsbD0iIzAwMCIgc3R5bGU9IiIvPjwvc3ZnPg==');
                            background-size: 100%;
                            height: 22px;
                            top: 2px;
                            width: 20px;
                        }
                    }
                }

                &:last-child {
                    & > div {
                        padding-inline-start: 30px;

                        &:before {
                            background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9InllcyI/Pjxzdmcgd2lkdGg9IjI4IiBoZWlnaHQ9IjI4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik01IDhoMTh2MTJINVY4eiIgZmlsbD0iI2ZmZiIgZmlsbC1vcGFjaXR5PSIuMTUiIHN0eWxlPSIiLz48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTIyIDlINnYxMGgxNlY5ek01IDh2MTJoMThWOEg1eiIgZmlsbD0iIzAwMCIgc3R5bGU9IiIvPjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNi4wMjQgOC4zMDNMNS4zIDkuMTJsNi4xMDMgNC44MDctNi4xMDIgNC45NTguNzM0LjgwNyA2LjIzNi01LjA2OUwxNCAxNi4wMDlsMS43MjgtMS4zODYgNi4yMzYgNS4wNy43MzUtLjgwOC02LjEwMy00Ljk1OUwyMi43IDkuMTJsLS43MjMtLjgxN0wxNCAxNC42MTIgNi4wMjQgOC4zMDN6IiBmaWxsPSIjMDAwIiBzdHlsZT0iIi8+PC9zdmc+');
                            background-size: 140% 200%;
                            height: 16px;
                            top: 50%;
                            margin-top: -8px;
                            width: 22px;
                        }
                    }
                }
            }
        }
    }

    section {
       h2 {
            position: relative;
            background-image: linear-gradient(0deg, $red, $red);
            background-size: 100% 2px;
            background-repeat: no-repeat;
            background-position: 0 0;

            @include text(16px, 19px,700, $grey);
            padding: 24px 20px;
            text-transform: uppercase;
            width: calc(100% + 2px);
            top: -1px;
            left: -1px;
           text-align: center;
        }

        p,ul,ol, table {
            padding: 0 20px 16px 20px;
        }
    }
}