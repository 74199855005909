$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.collection-info {
    width: 280px;
    min-height: 66px;
    background: $red;
    border-radius: 2px;
    margin: 0 auto;
    margin-top: 25px;
    box-sizing: border-box;
    padding: 12px 20px 15px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &.is-status- {
        border: 1px solid rgba(255, 255, 255, 0.5);
        background: transparent;
        .collection-info__container, .progress-bar {
            display: none;
        }
    }

    &.is-status-inactive {
        .collection-info__container {
            flex-direction: column-reverse;
            color: $yellow;
        }
    }
    &.is-status-inactive, &.is-status-completed {
        .progress-bar {
            display: none;
        }
    }
    &.is-status-completed {
        background: $yellow;
        color: #0B1222;
    }

    &__container {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: space-between;

        body.rtl-lang & {
            direction: rtl;
        }
    }

    &__text {
        font-weight: bold;
        line-height: 14px;
        overflow: hidden;
        white-space: break-spaces;

        .is-status-inactive & {
            text-align: center;
        }
        span:nth-child(2) {
            display: none;
        }
        .is-status-completed & {
            span:nth-child(1) {
                display: none;
            }
            span:nth-child(2) {
                display: block;
            }
        }
    }
    &__price {
        @include text(24px, 28px, bold);
        text-transform: uppercase;
        margin-inline-start: 4px;
    }
}