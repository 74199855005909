$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.chat-lang {
  display: flex;
  width: 100%;
  background-color: $lightBlue;
  border: 1px solid #394557;
  background-image: linear-gradient(to left, rgba(167, 175, 190, 0.1), rgba(167, 175, 190, 0.1));
  background-size: 1px 20px;
  background-repeat: no-repeat;
  background-position: center;

  &.has-one-item {
    background-image: none;

    .chat-lang__item {
      width: 100%;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 50%;
    padding: 8px 20px;
    height: 48px;
    @include text(14px, 16px, 400, $grey);
    overflow: hidden;
    flex-shrink: 0;

    @include hover() {
      opacity: 0.8;
    }

    & > span {
      max-height: 100%;
      overflow: hidden;
    }
  }

  &__lang {
    .lang__img {
      height: 44px;
      padding: 8px;
      width: 56px;
    }

    & > span > span {
      text-transform: uppercase;
    }
  }

  &__chat {
    background-position: -4px center;
    background-repeat: no-repeat;
    background-size: 24px;
    padding-inline-start: 20px;

    .sidemenu & {
      white-space: break-spaces;
    }

    body.rtl-lang & {
      background-position: 100% center;
    }
  }
}
