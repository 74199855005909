$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.has-txt-crab {
  color: #fff;
}

.has-txt-crab-free {
  color: #EFFB37;
}

.has-icon-crab {
  &::after {
    content: '';
    display: none;
  }
}

.has-icon-crab-free {
  &::after {
    content: '';
    width: 29px;
    height: 12px;
    position: absolute;
    background-image: url($cdn + '/rabona/crab/icon-free.png');
    background-size: cover;
    display: flex;
    margin: 0;
    top: 50%;
    inset-inline-end: 17px;
    transform: translateY(-50%);
  }
}