$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.header-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-start: span 2;
  justify-self: end;
  width: 116px;
  height: 32px;
  padding: 0 8px;
  margin-inline-end: 8px;
  background-color: $blue;
  overflow: hidden;

  @include text(14px, null, 700, #fff);
  text-transform: uppercase;

  &::before {
    width: 20px;
    height: 20px;
    margin-inline-end: 8px;
  }

  span {
    max-width: calc(100% - 28px);
    @include ellipsis;
  }

  @include hover {
    opacity: .8;
  }
}