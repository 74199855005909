$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.registration__step {
  .form-name {
    text-transform: uppercase;
    font-weight: 400;
    line-height: 1.2;
    font-size: 12px;
    margin-bottom: 8px;
  }
}
