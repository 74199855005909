$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.mwidth-100{
    max-width: 100%;
}

.is-reset-btn-styles{
    background-color: transparent;
    border: none;
}

.is-mt-20{
    margin-top: 20px;
}

.show-guest {
    body.linda-has-user & {
        display: none !important;
    }
}
.show-user {
    body:not(.linda-has-user) & {
        display: none !important;
    }
}

$margin-list: 0 2 4 5 8 10 12 14 15 16 18 19 20 22 24 25 26 28 30 32 36 40 44 48 60 92;


@for $i from 1 through length( $margin-list ) {
    .has-margin-bottom-#{nth( $margin-list, $i )} {
        margin-bottom: #{nth( $margin-list, $i )}px !important;
    }
    .has-margin-top-#{nth( $margin-list, $i )} {
        margin-top: #{nth( $margin-list, $i )}px !important;
    }
    .has-margin-right-#{nth( $margin-list, $i )} {
        margin-right: #{nth( $margin-list, $i )}px !important;
    }
}

.is-center{
    text-align: center !important;
}
.is-centered {
    margin: 0 auto;
}

.has-content-centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.has-full-width {
    width: 100%;
}

.d-ltr {
    body.rtl-lang & {
        direction: ltr;
    }
}

.text-capitalize {
    text-transform: capitalize;
}