$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.tournament-leaderboard {

    &__tr {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        margin-bottom: 2px;
        height: 44px;
        border-radius: 2px;
        @include text(14px, 24px, normal, $grey);

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {

            color: $white;

            .is-number {
                background-image: url($cdn + '/rabona/tournament/tournament-item-icon.svg');
                background-repeat: no-repeat;
                background-size: 33px auto;
                background-position: center top 10px;
                font-weight: bold;
            }
            
            &.is-current-user .is-number {
                .tournament-ao & {
                    background-image: url($cdn + '/rabona/tournament/tournament-item-win-icon.svg');

                }
            }
        }
        &:nth-child(2){
            background-color: $lightBlue;
        }
        &:nth-child(3),
        &:nth-child(4) {
            background-color: $darkBlue;
        }
        &.is-current-user {
            background-color: #BC1F39;
            color: #ECFD00;

            .is-number {
                background-image: url($cdn + '/rabona/tournament/tournament-item-win-icon.svg');
                background-repeat: no-repeat;
                background-size: 33px auto;
                background-position: center top 10px;
                .tournament-ao & {
                    background-image: none;
                }
            }
            
        }

    }
    &__td {
        width: 25%;
        @include  ellipsis;
        text-transform: uppercase;
        vertical-align: middle;

        &.is-fs-16 {
            font-size: 16px;
        }
        &.is-left {
            text-align: left;
            direction: ltr!important;
        }
        &.is-right {
            text-align: right;
        }
        &.is-center {
            text-align: center;
        }
        &.is-bold {
            font-weight: bold;
        }
        &.is-prize {
            width: 68px;
        }
        &.is-number {
            width: 33px;
            text-align: center;
            line-height: 44px;
        }
    }

    body.rtl-lang &__td.is-number {
        text-align: center;
    }

    &__place {
        width: 83px;
        text-align: center;
    }
    &__prize {
        font-weight: 700;
    }

    //Стили для заглушки

    .is-empty {
        margin: 8px auto 0;
        max-width: 280px;

        .tournament-leaderboard__text {
            @include text(19px, 24px, bold);
            text-align: center;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            margin-top: 24px;
            padding-bottom: 40px;
        }

    }

    body.rtl-lang &__table {
        direction: rtl;
    }
}
