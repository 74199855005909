$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.layout-vip-page {
  padding: 25px 16px 40px;
  background-image: url($cdn + '/rabona/vip/vip_lvl_bg/1024.jpg');
  background-size: auto 301px;
  background-position: top center;
  background-repeat: repeat-x;

  @include breakpoint($bp1) {
    padding: 25px 10px 50px;
  }
  @include breakpoint($bp3) {
    padding: 25px 16px 70px;
  }

}