$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.promo-page {
  padding: 0 20px 40px;
  position: relative;

  &__back {
    position: absolute;
    inset-inline-start: 8px;
    top: 8px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 14px;
    padding-right: 17px;
    color: #ffffff;
    font-size: 14px;
    line-height: 1em;
    text-transform: uppercase;
    z-index: 2;

    body.rtl-lang & {
      direction: rtl;
    }

    &::before {
      content: '';
      display: block;
      width: 10px;
      height: 18px;
      background-image: var(--icon-arrow-left);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      flex-shrink: 0;
      margin-inline-end: 8px;
      filter: brightness(0) saturate(100%) invert(99%) sepia(100%) saturate(2%) hue-rotate(117deg) brightness(105%) contrast(100%);

      body.rtl-lang & {
        rotate: 180deg;
      }
    }
  }

  &__terms {
    margin-bottom: 20px;
  }

  &__terms-title {
    @include text(16px, 19px, bold, $grey);
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 20px;
  }

  &__terms-content {
    @include text(16px, 24px, normal, $grey);

    & p {
      padding-bottom: 12px;

      &>span {
        display: inline-block;
        font-weight: 700;
        padding-inline-end: 4px;
        min-width: 24px;
      }
    }

    & tr {
      background: $darkBlue;
      border-bottom: 4px solid $black;
      height: 48px;
      text-align: center;
    }

    & th {
      text-align: center;
    }

    & td {
      padding: 0 12px;
      text-align: center;
    }

    & table {
      width: 100%;
      margin-bottom: 12px;
    }

    ul,
    ol {
      padding-block-end: 8px;
      padding-inline-start: 44px;

      li {
        margin-bottom: 4px;
      }
    }

    ul {
      list-style: disc;
      visibility: visible;
    }
  }

  &__btn {
    min-width: 88px;
    margin: 0 auto;
    display: table;
  }

  &__opt-in-block {
    background-color: #1C2738;
    padding: 8px;
    text-align: center;
    color: #A5AFC0;
    font-size: 12px;
    line-height: 1.4;
    width: 280px;
    margin: 0 auto;

    .is-link {
      color: inherit;
      display: block;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__opt-in-text {
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: '';
      width: 16px;
      height: 16px;
      margin-inline-end: 4px;
      background-image: var(--icon-choose-gray);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 13px;
    }
  }

  &__opt-out-text {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  &__action-link {
    padding: 4px 12px;
    text-decoration: underline;
    color: #A5AFC0;
    font-size: 16px;
    line-height: 1.4;
  }

  @include breakpoint($bp3) {
    &__terms {
      max-width: 780px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.promo-page .preloader {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  z-index: 1;
  background-color: rgba(31, 39, 55, 0.8);
}