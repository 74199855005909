$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.modal-title {

  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;

  color: #FFFFFF;

  margin-bottom: 8px;
  margin-top: 16px;

  &.is-left{
    text-align: left;
  }
  &.is-line {
    padding-bottom: 20px;
    margin-bottom: 0;
    border-bottom: 2px solid #CC0034;
  }

  @include breakpoint( $bp1 ){
    font-size: 28px;
    line-height: 33px;
    margin-top: 0;
    margin-bottom: 16px;
  }
  @include breakpoint( $bp3 ){
    text-align: center !important;
  }


}