$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.cashbox-interac {
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: #576477;
  text-align: center;
}
