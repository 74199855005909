$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.header {
  width: 100%;
  //display: grid;
  //grid-template-columns: 56px 56px auto 56px 56px;
  //grid-template-rows: 48px;
  //grid-gap: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 48px;
  background-color: $darkBlue;
  padding: 0 4px;

  &:not(.is-casino) .show-on-casino {
    display: none !important;
  }

  &.is-casino .hide-on-casino {
    display: none !important;
  }

  &__left-side,
  &__right-side {
    display: flex;
    column-gap: 4px;
  }

  .btn-icon {
    &.is-active {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  .icon-burger {
    scale: -1 1;
  }
}