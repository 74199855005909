$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.quick-dep-el {
  position: relative;
  z-index: 100;
  &.has-quick-dep {
    order: 1;
  }
}

.quick-dep {
  $root: &;
  $size-s: 52px;
  $size-m: 62px;

  flex-shrink: 0;

  body.rtl-lang & {
    direction: rtl;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 4px 12px;
    background-color: #172032;
    background-image: url($cdn + '/rabona/quick-dep/bg-mobile.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    @include breakpoint($bp2) {
      height: 46px;
    }
  }

  &__status {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 20px 0;
    min-height: 50px;
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.36));
    z-index: 10;

    &--processing {
      justify-content: center;
      background-color: #333;
    }

    &--success {
      padding-left: 52px;
      justify-content: space-between;
      background-color: #00c400;
      background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath d='M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zM6 8l-2 2 5 5 7-7-2-2-5 5-3-3z' fill='%23fff'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: left 18px top calc(50% + 5px);
      background-size: 20px auto;
    }

    &--rejected {
      padding-left: 52px;
      justify-content: space-between;
      background-color: #cc0034;
      background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath d='M10 0a10 10 0 100 20 10 10 0 000-20zm5.701 13.644l-2.058 2.058L10 12.058l-3.644 3.643-2.058-2.058L7.943 10 4.299 6.356l2.058-2.057L10 7.942l3.644-3.644 2.058 2.058L12.057 10l3.644 3.644z' fill='%23fff'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: left 18px top calc(50% + 5px);
      background-size: 20px auto;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      height: 10px;
      background-color: inherit;
      border-bottom-left-radius: 100%;
      border-bottom-right-radius: 100%;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.36);
      z-index: 10;
    }
  }

  &__status-close {
    width: 16px;
    height: 16px;
    background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M9.63 8l6.08-6.07a1.12 1.12 0 00-.06-1.57A1.12 1.12 0 0014.07.3L8 6.32 1.93.3A1.13 1.13 0 00.35.36a1.12 1.12 0 00-.06 1.57L6.33 8 .29 14.07c-.41.46-.38 1.16.07 1.58.41.45 1.12.47 1.57.05L8 9.64l6.07 6.06a1.17 1.17 0 001.64-1.63L9.63 8z' fill='%23FAFBFC'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
  }

  &__status-text {
    flex-grow: 1;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    color: white;

    #{$root}__status--processing & {
      flex-grow: 0;
      color: #ecfd00;
    }
  }

  &__preloader {
    margin: 0;
    margin-right: 10px;
    width: 40px;
    height: 40px;
  }

  &__open {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    height: 24px;
    border: none;
    background-color: #ecfd00;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.2;
    text-transform: uppercase;
    color: #0b1222;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    outline: none;
    cursor: pointer;

    &::before {
      content: '';
      margin-inline-end: 10px;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 12'%3E%3Cpath d='M19.93 2.78A2.24 2.24 0 0017.74 0H9.12C8.17 0 7.32.58 7 1.46l-.5 1.32h13.43z' fill='%230B1222'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.8 4.7l-.86 2.29A2.24 2.24 0 007.06 10h5.44a3.75 3.75 0 015.9-3.07l.83-2.23H5.8zm5.86 2.69l-.07.17a.44.44 0 01-.41.27H6.33a.44.44 0 01-.41-.6l.07-.18a.44.44 0 01.4-.27h4.86c.32 0 .53.32.4.6z' fill='%230B1222'/%3E%3Cpath d='M1.57 4.92H4.3c.21 0 .4-.13.49-.32a.53.53 0 00-.5-.73H1.58c-.22 0-.41.13-.5.32-.14.35.12.73.5.73zM5.34 2H2.6a.53.53 0 110-1.06h2.74a.53.53 0 110 1.05zM.53 7.85h2.74c.22 0 .41-.13.5-.33a.53.53 0 00-.5-.72H.53c-.21 0-.4.12-.49.32-.14.35.11.73.5.73z' fill='%230B1222'/%3E%3Cpath d='M14.53 9.97h2.9l-.29.34-.76.75c-.1.12-.12.3-.04.45.1.14.27.18.4.07a.3.3 0 00.03-.03l1.43-1.67a.37.37 0 000-.47l-1.43-1.67a.26.26 0 00-.4 0l-.03.03a.39.39 0 00.04.45l.76.76c.05.04.29.33.29.33h-2.84c-.14-.01-.27.11-.3.28-.03.18.08.35.24.38z' fill='%230B1222' stroke='%230B1222' stroke-width='.3'/%3E%3C/svg%3E");

      body.rtl-lang & {
        scale: -1 1;
      }
    }

    @include breakpoint($bp2) {
      font-size: 16px;
      height: 30px;
    }
  }

  &__footer {
    width: 100%;
    padding: 12px;
    background-color: #172032;
    background-image: url($cdn + '/rabona/quick-dep/bg-mobile.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: 100;
  }

  &__form {
    display: grid;
    grid-template-columns: 1fr min-content;

    &.ng-invalid-min,
    &.ng-invalid-max,
    &.ng-invalid-required {
      & #{$root}__input-error {
        display: block;
      }
    }

    &.ng-invalid-min #{$root}__input-error .g-min,
    &.ng-invalid-max #{$root}__input-error .g-max,
    &.ng-invalid-required #{$root}__input-error .g-required {
      display: block;
    }
  }

  &__wrap {
    display: grid;
    grid-template-columns: 36% 1fr;
  }

  &__form-label {
    display: block;
    margin-bottom: 2px;
    font-weight: 400;
    font-size: 12px;
    line-height: 1;
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include breakpoint($bp2) {
      font-size: 14px;
    }
  }

  &__form-row {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 22px 1fr 20px;
    align-items: center;
    column-gap: 4px;

    @include breakpoint($bp2) {
      column-gap: 8px;
    }

    &--input {
      grid-template-columns: min-content 1fr;
      width: 100%;
    }
  }

  &__select,
  &__input {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: $size-s;
    padding: 8px 6px 8px 12px;
    background-color: #273242;
    border-right: 1px solid #162334;

    @include breakpoint($bp2) {
      height: $size-m;
    }
  }

  &__select-scroll {
    width: 100%;
    max-height: 156px;
    overflow-y: auto;
  }

  &__select-arrow {
    display: block;
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 6'%3E%3Cpath d='M.84.2l4.1 4.04L9.1.2l.88.76L4.94 5.8.02.96.84.2z' fill='%23A5AFC0'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10px auto;

    &.has-rotate {
      transform: rotate(180deg);
    }
  }

  &__select-window {
    position: absolute;
    bottom: 53px;
    left: -1px;
    right: 0;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 37px;
      background-image: linear-gradient(to top, #273242 1.04%, rgba(39, 50, 66, 0) 100%);
      z-index: 1;
    }
    @include breakpoint($bp2) {
      bottom: 63px;
    }
  }

  &__select-row {
    @extend #{$root}__form-row;

    grid-template-columns: 22px 1fr;
    height: $size-s;
    padding-left: 12px;
    padding-right: 12px;
    background-color: #273242;
    border-top: 1px solid #162334;

    &:last-child {
      position: relative;
      z-index: 5;
    }

  }

  &__add-card {
    padding-inline-start: 40px;
    padding-inline-end: 12px;
    align-items: center;
    text-align: start;
    height: $size-s;
    width: 100%;
    background-color: #273242;
    background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 20'%3E%3Cpath d='M11 6H.57v12.8h16.29v-7.15A6.4 6.4 0 0110.99 6z' fill='%23fff' fill-opacity='.15'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.8 8h4.65v3.1H2.79V8zm1.39 1.4H6v.3H4.2v-.3z' fill='%23A5AFC0'/%3E%3Cpath d='M2.8 15.03v1.5h3.48v-1.5H2.8zM6.78 15.03h3.5v1.5h-3.5v-1.5zM10.76 15.03v1.5h3.5v-1.5h-3.5z' fill='%23A5AFC0'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.95 11.1v6.88H1.4V6.68h9.77v-1.4H0v14.1h17.35v-8.29h-1.4z' fill='%23A5AFC0'/%3E%3Cpath d='M19.93 8.63l-2.7.58-2.87-1.04-1.3-3.02 1.17-2.4a4.05 4.05 0 017.16 2.53c0 .87-.28 1.71-.8 2.4l-.66.95z' fill='%23fff' fill-opacity='.15'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.04 2.95h-1.4v1.63h-1.52v1.4h1.59V7.6h1.4V5.98h1.57v-1.4h-1.64V2.95z' fill='%23A7AFBE'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.35.62a4.65 4.65 0 100 9.31 4.65 4.65 0 000-9.3zm0 8a3.35 3.35 0 110-6.7 3.35 3.35 0 010 6.7z' fill='%23A7AFBE'/%3E%3C/svg%3E");
    background-size: 22px auto;
    background-position: left 12px center;
    background-repeat: no-repeat;
    font-size: 14px;
    line-height: 1.1;
    color: white;
    border: none;
    cursor: pointer;
    outline: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include breakpoint($bp2) {
      padding-inline-start: 48px;
      background-size: 28px auto;
    }

    body.rtl-lang & {
      background-position: left calc(100% - 12px) center;
    }
  }

  &__currency,
  &__value {
    font-size: 16px;
    line-height: 1.1;

    @include breakpoint($bp2) {
      &.is-big {
        font-size: 24px;
      }
    }
  }

  &__currency {
    color: #768088;
  }

  &__value {
    color: #f5f6f9;
    @include ellipsis;
  }

  &__card {
    display: block;
    width: 22px;
    height: 22px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center;

    @include breakpoint($bp2) {
      &.is-big {
        width: 28px;
        height: 28px;
      }
    }
  }

  &__field {
    @extend #{$root}__value;
    padding: 0;
    margin: 0;
    background-color: transparent;
    min-width: 0;
    width: 100%;
    border: none;
    outline: none;
    appearance: none;

    &[type="number"] {
      appearance: textfield;
    }
  }

  &__input-error {
    display: none;
    position: absolute;
    bottom: calc(100% + 11px);
    left: -1px;
    right: -1px;
    padding: 13px 16px;
    background-color: #eff5fd;
    text-align: center;
    z-index: 20;

    &::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 11px 10px 0 10px;
      transform: translateX(-50%);
      border-color: #eff5fd transparent transparent transparent;
    }
  }

  &__input-error-text {
    display: none;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 14px;
    color: #ff2469;

    @include breakpoint($bp2) {
      font-size: 16px;
    }
  }

  &__submit {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px 14px;
    min-width: $size-s;
    min-height: $size-s;
    height: 100%;
    background-color: #ecfd00;
    font-weight: bold;
    font-size: 0;
    line-height: 1.1;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #0b1222;
    border: none;
    outline: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;

    &::before {
      content: '';
      width: 31px;
      height: 31px;
      background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 12'%3E%3Cpath d='M19.93 2.78A2.24 2.24 0 0017.74 0H9.12C8.17 0 7.32.58 7 1.46l-.5 1.32h13.43z' fill='%230B1222'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.8 4.7l-.86 2.29A2.24 2.24 0 007.06 10h5.44a3.75 3.75 0 015.9-3.07l.83-2.23H5.8zm5.86 2.69l-.07.17a.44.44 0 01-.41.27H6.33a.44.44 0 01-.41-.6l.07-.18a.44.44 0 01.4-.27h4.86c.32 0 .53.32.4.6z' fill='%230B1222'/%3E%3Cpath d='M1.57 4.92H4.3c.21 0 .4-.13.49-.32a.53.53 0 00-.5-.73H1.58c-.22 0-.41.13-.5.32-.14.35.12.73.5.73zM5.34 2H2.6a.53.53 0 110-1.06h2.74a.53.53 0 110 1.05zM.53 7.85h2.74c.22 0 .41-.13.5-.33a.53.53 0 00-.5-.72H.53c-.21 0-.4.12-.49.32-.14.35.11.73.5.73z' fill='%230B1222'/%3E%3Cpath d='M14.53 9.97h2.9l-.29.34-.76.75c-.1.12-.12.3-.04.45.1.14.27.18.4.07a.3.3 0 00.03-.03l1.43-1.67a.37.37 0 000-.47l-1.43-1.67a.26.26 0 00-.4 0l-.03.03a.39.39 0 00.04.45l.76.76c.05.04.29.33.29.33h-2.84c-.14-.01-.27.11-.3.28-.03.18.08.35.24.38z' fill='%230B1222' stroke='%230B1222' stroke-width='.3'/%3E%3C/svg%3E");
      background-size: 100% auto;
      background-position: center;
      background-repeat: no-repeat;

      @include breakpoint($bp1) {
        margin-right: 12px;
      }

      @include breakpoint($bp2) {
        width: 32px;
        height: 32px;
      }

      body.rtl-lang & {
        scale: -1 1;
      }
    }

    @include breakpoint($bp1) {
      font-size: 20px;
    }

    @include breakpoint($bp2) {
      font-size: 24px;
      min-width: $size-m;
      min-height: $size-m;
    }
  }

  &__hide {
    margin-top: 14px;
    margin-bottom: 4px;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    background-color: transparent;
    border: none;
    outline: none;
    color: #a5afc0;

    &::before {
      content: '';
      margin-inline-end: 9px;
      width: 6px;
      height: 16px;
      background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 16'%3E%3Cpath d='M3 16l.15-.01.1-.03.04-.01a.77.77 0 00.1-.05l.03-.02a.74.74 0 00.11-.09l2.25-2.08c.3-.27.3-.71 0-.98a.8.8 0 00-1.06 0l-.97.9V8c0-.38-.34-.7-.75-.7s-.75.32-.75.7v5.63l-.97-.9a.8.8 0 00-1.06 0 .67.67 0 00-.22.49c0 .18.07.35.22.49l2.25 2.09h.01l.1.08.05.02.08.05.06.01.08.03L3 16zM3 6.26c.41 0 .75-.31.75-.7V3.49c0-.39-.34-.7-.75-.7s-.75.31-.75.7v2.09c0 .38.34.7.75.7zM3.75 1.4V.7C3.75.3 3.41 0 3 0s-.75.31-.75.7v.7c0 .38.34.69.75.69s.75-.31.75-.7z' fill='%23A5AFC0'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 100%;
    }
  }
}
