$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.popup-terminate {
    display: none; 
    padding: 0 20px 37px;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    .is-terminate & {
        display: flex;
        position: fixed;
        z-index: 9999;
        width: 100%;
        height: 100%;
        top: 0;
        background-color: $red;
        background-size: auto 100%;
        background-position: center;
        background-repeat: no-repeat;
        @include breakpoint( $bp1 ){
            padding-bottom: 25vh;
        }
        @include breakpoint( $bp2 ){
            background-position: center 27px;
            padding-bottom: 29vh;
        }
        @include breakpoint( $bp3 ){
            background-position: center 40px;
            padding-bottom: 36vh;

        }
    }
    &__title {
        @include text(24px, 32px, bold, $white);
        text-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 20px;

        background-color: rgba(204, 0, 52, 0.8);
        border-radius: 20px;
        box-shadow: 0 0 20px 20px rgba(204, 0, 52, 0.8);

        white-space: break-spaces;

        @include breakpoint( $bp2 ){
            @include text(40px, 48px, bold, $white);
        }

        &.small-font {
            font-size: 18px;
            @include breakpoint($bp440) {
              font-size: 24px;
            }
        }
    }
    &__btn {
        margin-bottom: 12px;
        @include breakpoint( $bp2 ){
            max-width: 280px;
        }
            
    }
    &__close {
        @include text(16px, 19px, normal, $white);
        border: none;
        min-width: 160px;
        max-width: 100%;
        padding: 14px 4px;
        text-align: center;

        background-color: rgba(204, 0, 52, 0.8);
        border-radius: 20px;
        box-shadow: 0 0 10px 10px rgba(204, 0, 52, 0.8);

        @include ellipsis;
        @include hover(){
            color: #CBD0D8;
            text-decoration: none;
        }
    }

}