$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.cashbox-balance {
  display: flex;
  margin: 16px 0 12px;
  justify-content: center;

  padding-left: 24px;
  padding-right: 24px;

  @include text(14px, 17px, 400, $grey);

  &::before {
    width: 16px;
    height: 16px;
    margin-inline-end: 8px;
  }
}