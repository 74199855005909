$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.link-tg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; 
    min-height: 48px;
    @include text(14px, 16px, 400, #ECFD00);
    text-align: center;
    padding: 0 10px;
    background-color: #2A394E;
    border: 1px solid #394557;

    &::before {
        content: '';
        width: 16px; 
        height: 16px;
        background-image: var(--icon-telegram);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 8px;
    }

    @include hover(){
        opacity: 0.8;
    }
}