$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.balance-filter-item {
  display: inline-block;
  margin-block: 0 20px;
  margin-inline-end: 12px;
  &__title {
    @include text(12px, 14px);
    margin-bottom: 18px;
    text-transform: uppercase;
    color: $grey;
    padding-top: 20px;
  }
  input {
    display: none;
  }
  label {
    display: block;
    color: $white;
    background: #303542;
    background: rgba(30,39,55,0.5);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 2px;
    padding: 8px;
    @include text(16px, 19px);
    max-height: 36px;
  }
  input:checked ~ label {
    color: $black;
    background-color: $yellow;
    border: 1px solid inherit;
  }

  &.is-status {
    display: none;

    &.linda-first-in-group {
      margin-top: 50px;
      position: relative;
    }
    .balance-filter-item__title {
      position: absolute;
      top: -50px;
      white-space: nowrap;
    }
  }
  &.linda-is-active {
    display: inline-block;
  }
  &.linda-is-active + .linda-empty-element {
    display: block;
    border-top: 1px solid $blue;
  }
  &__title {
    .is-status & {
      display: none;
    }
    .linda-first-in-group & {
      display: block;
    }
  }
}