$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.layout-achievement {
    padding: 12px 0;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 12px 20px;

        body.rtl-lang & {
            direction: rtl;
        }

        .title {
            margin: 0;
        }
    }

    &__link {
        font-size: 14px;
    }
}