$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.form-input {
  display: block;
  box-sizing: border-box;
  height: 52px;
  width: 100%;
  max-width: 100%;
  padding-block: 18px;
  padding-inline: 16px 52px;
  border-radius: 2px;
  background-color: #273242;
  border: 1px solid #2A394E;
  font-size: 16px;
  line-height: 120%;
  color: #FFFFFF;
  transition: .1s;
  background-repeat: no-repeat;
  background-position: right 16px center;

  &[type=number] {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }


  &::placeholder {
    color: #576477;
  }

  &:focus {
    border-color: $yellow;
  }

  &.ng-valid.ng-dirty:not(.not-status) {
    background-image: var(--icon-i-success);
  }

  &:not(.not-status) {
    &.ng-invalid.ng-touched:not(:focus) {
      background-image: var(--icon-i-error);
    }
  }

  &.ng-invalid.ng-touched:not(:focus) {
    color: #FF2469;

    &::placeholder {
      color: #FF2469;
    }
  }

  &.is-light {
    background-color: #F0F5FC;
    border: 1px solid #A7AFBE;
    box-sizing: border-box;
    border-radius: 2px;
    color: #0C1221;

    &::placeholder {
      color: #A7AFBE;
    }

    &:focus {
      border-color: $yellow;
    }
  }

  &:disabled {
    background-color: transparent;
    color: #596475;
    background-image: var(--icon-i-success);
  }

  &.has-removed-arrows-for-type-number {

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.form-label.is-light .form-input {
  color: #2d394c;
  background-color: #f0f5fc;
  border: 1px solid #a7afbe;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
}

.form-label.has-deposit-input .form-input {
  height: 48px;
  text-align: center;
  border: 1px solid #2d394c;
  padding: 14px;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
}


body.rtl-lang {
  .form-input {
    direction: rtl;
    text-align: right;
    padding-block: 18px;
    padding-inline: 16px 30px;
    background-position: left 8px center;

    &[rabona-split-phone] {
      direction: ltr;
    }
  }
}
