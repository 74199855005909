$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.balance-history-empty {
  padding: 12px 20px 0;
  text-align: center;

  &__title {
    @include text(20px, 23px);
    text-transform: uppercase;
    margin-bottom: 16px;
  }
  &__text {
    @include text(16px, 24px, normal, $grey);
    margin-bottom: 20px;
  }
  &__btn {
    max-width: 280px;
    margin: 0 auto;
  }
  &__chat-link {
    position: relative;
    display: inline-block;
    @include text(16px, 48px);
    margin-top: 20px;
    cursor: pointer;

    &::before {
      content: "";
      width: 20px;
      height: 100%;
      position: absolute;
      inset-inline-start: 0;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}