$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.collection-slider {
    height: 192px;
    position: relative;
    @include breakpoint( $bp1 ) {
        background-position: center top;        
    }
    @include breakpoint( $bp2 ) {
        height: 272px;
        background-size: auto 100%;
    }
    @include breakpoint( $bp3 ) {
        height: 372px; 
    }
    @include breakpoint( $bp4 ) {
        background-size: cover;
    }
    &__container {
        height: 192px;
        background-size: cover;
        background-position: center bottom;
        background-repeat: no-repeat;
        @include breakpoint( $bp2 ) {
            height: 272px;
        }
        @include breakpoint( $bp3 ) {
            height: 372px; 
        }
    }
    &__item {
        position: relative;
        height: 192px;
        &::after {
            content: '';
            width: 100%;
            height: 92px;
            position: absolute;
            inset-inline-start: 0;
            bottom: 0;
            background: linear-gradient(180deg, rgba(12, 18, 33, 0) 0%, #0C1221 100%);
        }
        @include breakpoint( $bp2 ) {
            height: 272px;
        }
        @include breakpoint( $bp3 ) {
            height: 372px; 
        }
    }
    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-block: 12px 0;
        padding-inline-start: 12px;
        padding-inline-end: 20px;
        position: absolute;
        width: 100vw;
        z-index: 2;
        .is-yellow {
            color: $yellow;
        }

        body.rtl-lang & {
            direction: rtl;
        }
    }
    &__subtitle {
        @include text(14px, 16px, bold);
        max-width: 60%;
        font-style: italic;
        text-align: center;
        @include ellipsis;
    }
    &__title {
        @include text(28px, 33px, bold);
        text-align: center;
        text-transform: uppercase;
        text-shadow: 0px 6px 8px #000000;
        padding: 7px 20px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        position: relative;
        height: 70px;
        overflow: hidden;
        z-index: 1;
    }
    &__img {
        width: 100vw;
        height: 170px;
        background-size: 465px auto;
        background-position: 40% bottom;
        background-repeat: no-repeat;
        position: absolute;
        bottom: -14px;
        z-index: -1;
        @include breakpoint( $bp1 ) {
            background-position: center center;
        }
        @include breakpoint( $bp2 ) {
            height: 232px;
            background-size: auto 100%;
            background-position: center bottom;
        }
        @include breakpoint( $bp3 ) {
            height: 332px;
        }
    }
    &__btn {
        @include text(14px, inherit, normal);
        max-width: 35%;
        height: 40px;
        background: rgba(12, 18, 33, 0.4);
        border-radius: 2px;
        padding: 0 16px;
        margin-inline-end: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        text-decoration: none;
        color: $white;
        outline: none;
        @include hover(){
            text-decoration: none;
            background: $lightBlue;
        }
        .icon-arrow-left {
            width: 10px;
            height: 18px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            margin-inline-end: 8px;

            body.rtl-lang & {
                scale: -1 1;
            }
        }
        .btn-text {
            max-width: 70px;
            @include ellipsis;
        }
    }
    &__info {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    .slick-prev,
    .slick-next {
        width: 32px;
        height: 80px;
        border: none;
        background: rgba(12, 18, 33, 0.6);        
        color: transparent;        
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9InllcyI/Pjxzdmcgd2lkdGg9IjEwIiBoZWlnaHQ9IjE2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik05LjIgMS43NjJMMy4xMzQgNy45MTUgOS4yIDE0LjE1NmwtMS4xNCAxLjMxTC44IDcuOTE1IDguMDYuNTMzIDkuMiAxLjc2MnoiIGZpbGw9IiNBN0FGQkUiIHN0eWxlPSIiLz48L3N2Zz4=');
        background-repeat: no-repeat;
        background-size: 7px 12px;
        background-position: center center;
        clip-path: polygon(0 0, 100% 10%, 100% 90%, 0% 100%);
        position: absolute;
        bottom: -28px;
        z-index: 9;
        
    }
    .slick-prev {
        inset-inline-start: 0;        
    }
    .slick-next {
        inset-inline-end: 0;
        transform: rotate(180deg);
    }    
}
