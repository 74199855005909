$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.notifications {
  position:fixed;
  top: 8px;
  right: 8px;
  width: 280px;
  z-index: 999999999;

  body.rtl-lang & {
    direction: rtl;
  }

  &__item {
    position:relative;
    background: #FFFFFF;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.25);
    padding-block: 10px 12px;
    padding-inline: 56px 48px;
    box-sizing: border-box;
    opacity: 1;
    width: 100%;

    margin-bottom: 8px;

    transition: 0.3s;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: 15px center;
    background-size: 32px;

    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #2D394C;
    letter-spacing: 0.02em;

    &.icon-notification-warning {
      border-top: 2px solid #FF446C;
    }
    &.icon-notification-info {
      border-top: 2px solid #EFFB37;
    }
    &.icon-notification-success {
      border-top: 2px solid #26C000;
    }
    &.icon-notification-error {
      border-top: 2px solid #CC0034;
    }

    body.rtl-lang & {
      background-position: calc(100% - 15px) center;
    }

    span {
      display: block;
      margin-bottom: 2px;

      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      align-items: center;
      letter-spacing: 0.02em;
      color: #BFB5C8;
    }

    &:after {
      content: '';
      width: 20px;
      height: 20px;
      position: absolute;
      inset-inline-end: 16px;
      top: calc(50% - 10px);
      background-position: center;
      background-repeat: no-repeat;
    }

    &.ng-move,
    &.ng-enter {
      transition: 0.5s;
    }
    &.ng-leave{
      transition: 0.001s !important;
    }
    &.ng-leave.ng-leave-active,
    &.ng-move,
    &.ng-enter {
      margin-top: -70px;
    }
    &.ng-leave,
    &.ng-move.ng-move-active,
    &.ng-enter.ng-enter-active {
      margin-top: 10px;
    }
  }

}