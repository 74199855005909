$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.form-item {

  position: relative;

  & .icon-i-calendar {
    background-repeat: no-repeat;
    background-position: right 20px center;
    background-size: 20px;
  }
  &__phone-data{
    display: grid;
    grid-template-columns: 112px 1fr;
    gap: 4px;
    &-code{
      position: relative;
      & select {
        width: 100%;
        height: 100%;
        appearance: none;
        border: none;
        background: transparent;
      }

      & .is-falsy{
        position: absolute;
        width: 100%;
        height: 100%;
        pointer-events: none;
        border-radius: 2px;
        padding: 16px 20px;
        border: 1px solid #293241;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        color: #fff;
        background-color: #293241;
        background-repeat: no-repeat;
        background-repeat: no-repeat;
        background-position: 90% center;
        top: 0;
        left: 0;

        body.rtl-lang & {
          background-position: 20px center;
        }
      }
    }
  }

}