$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.header-logo {
  //align-self: start;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 54px;
  height: 50px;
  background-image: url($cdn + '/rabona/mobile/light.png');
  background-size: auto 100%;
  background-position: top center;
  background-repeat: no-repeat;

  //position: relative;

  &.show-arrow:after {

    content: '';
    width: 12px;
    height: 12px;
    position: absolute;
    bottom: -2px;
    left: calc(50% - 6px);
    background-size: contain;
    background-position: center;

  }

  &__tooltip {

    display: none;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 16px;
    color: #0C1221;

    width: 110px;
    height: 32px;
    background: #F0F5FC;
    position: absolute;
    top: 60px;
    left: calc(50% - 55px);

    animation: bounce 1s infinite;

    .show-tooltip & {
      display: flex;
    }

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;

      border-bottom: 8px solid #F0F5FC;

      position: absolute;
      top: -8px;
      left: calc(50% - 8px);
    }
  }

}