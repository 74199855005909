$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.form-label {
  
  display: block;

  width: 100%;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  text-transform: uppercase;

  color: #FFFFFF;

  margin-bottom: 8px;

  &.is-light{
    margin-bottom: 20px;
  }

  &.is-bank-id{
    position: relative;
  }

  &.show-placeholder {
    position: relative;
    &:after {
      content: attr(placeholder);
      position: absolute;
      top: 1px;
      left: 20px;
      margin-bottom: 0;
      background: $inputBg;
      z-index: 99;
      color: $inputColorPlaceholder;
      width: 60%;
      text-align: left;
      overflow: hidden;
      font-size: 14px;
      font-weight: normal;
      line-height: 50px;
      pointer-events: none;
    }
  }
}