$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.profile-session {

  font-size: 14px;
  line-height: 150%;
  text-align: center;

  color: #A7AFBE;

  margin: 20px 0 8px;

  strong {
    body.rtl-lang & {
      display: inline-block;
      direction: ltr;
    }
  }

}