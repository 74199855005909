$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.home-bonuses {
    background: #192333;
    background-repeat: no-repeat, repeat;
    background-position: top right, top;
    background-size:  0, auto;
    height: 160px;
    padding: 0 20px 0 0;
    grid-gap: 8px;
    display: grid;
    grid-template-columns: 100px 1fr;
    &.is-crab {
        background-image: var(--image-banner-mid), var(--image-bg);
    }
    &__img {
        width: 100%;
        background-position: center;
        background-size: cover;
        .is-crab &{
            background-image: var(--image-banner-mid);
        }
        .casino-first-banner & {
            background-position: right;  
        }
    }
    &__wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
    }
    &__title {
        max-height: 99px;
        min-height: 72px;
        overflow: hidden;
        @include text(28px, 33px, 700);
        margin-bottom: 6px;
        text-transform: uppercase;
        text-align: right;
        white-space: break-spaces;
    }

    body.rtl-lang &__btn{
        text-align: center;
    }

    @include breakpoint( $bp1 ) {
        background-position: top right, top;
        grid-template-columns: 200px 1fr;
        grid-gap: 10px;
        &.is-crab {
            background-image: var(--image-banner-big), var(--image-bg);
        }
        &__title {
            margin-bottom: 12px;
            min-height: 41px;

        }
        &__img {
            .is-crab & {
                background-image: var(--image-banner-big); 
            }
        }
    }
    @include breakpoint( $bp2 ) {
        padding: 0 220px 0 0;
        background-size: 200px 160px, auto;
        grid-template-columns: 200px 1fr;
        grid-gap: 20px;
        &__title {
            text-align: center;
        }
        &__wrap {
            align-items: center;
        }
    }

}