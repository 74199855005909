$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.cashbox-tint{
    margin-top: 20px;
    padding-block: 10px;
    padding-inline: 16px 12px;
    background-color: $red;
    min-height: 48px;
    @include text(12px, 14px, 400, #fff);
    display: flex;
    align-items: center;
    border-radius: 2px;
    &::after{
        width: 20px;
        height: 20px;
        filter: $filterWhite;
        flex-shrink: 0;

        body.rtl-lang & {
            rotate: 180deg;
        }
    }

    @include hover(){
        text-decoration: none;
    }

    &__sum {
        body.rtl-lang & {
            direction: ltr;
        }
    }

    &__amnt{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        min-width: 60px;
        flex-shrink: 0;
        @include text(16px, 19px, 700, #fff);
        margin: 0 8px;
    }

    &__text{
        text-align: start;
        flex-grow: 1;
        overflow: hidden;
    }
}