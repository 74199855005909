$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.quick-registration-widget {
  width: 260px;
  position: absolute;
  top: 0;

  &__title {
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #ffcf01;
    text-shadow: 0 1px 0 #cc2500, 0 4px 4px rgba(0,0,0,.35);
    padding-bottom: 10px;
  }

  &__wrap {
    background: #162334;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid #273242;

    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &__input-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    position: relative;
  }

  &__form-buttons { }
}
