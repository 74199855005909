$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.game-item2 {
  width: 100%;
  height: 67px;
  margin: 4px 0;
  display: flex;
  align-items: center;
  gap: 16px;

  @include hover(){
    opacity: .9;
  }

  img {
    width: 100px;
    height: 100%;
    object-fit: cover;
    border-radius: 2px;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  &__name {
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;

    span {
      display: block;
      font-size: 12px;
      line-height: 14px;
      color: #A7AFBE;
      margin-top: 1px;
    }
  }

}