$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.game-slider {
    margin: 8px 0 24px;
    height: 160px;

    &__container {
        position: relative;
        display: flex;
        justify-content: space-between;
        height: 160px;
        padding: 0 20px 10px;
        background-size: 100% 86px;
        background-position: top center;
        background-repeat: no-repeat;
    }

    &__info {
        text-transform: uppercase;
        position: relative;
        display: inline-grid;
        z-index: 1;
        align-content: end;
        justify-items: flex-start;
        height: 100%;
        max-width: 320px;
        overflow: hidden;
        opacity: 0;
        transform: translateX(-100%);
        transition-duration: calc(var(--animation-duration) * 2);
        transition-property: transform, opacity;

        .slick-active & {
            transform: translateX(0);
            opacity: 1;
        }
    }

    &__btn {
        max-width: 160px;
    }

    &__img {
        position: absolute;        
        top: 0;
        inset-inline-start: 50%;
        width: 100%;
        height: 86px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0;
        transform: translate(-50%, 100%);
        transition-duration: calc(var(--animation-duration) * 2);
        transition-property: transform,
        opacity;

        .slick-active & {
            transform: translate(-50%, 0);
            opacity: 1;

            body.rtl-lang & {
                transform: translate(50%, 0);
            }
        }

        &:after {
            content: '';
            width: 100%;
            height: 74px;
            position: absolute;
            bottom: -1px;
            left: 0;
            background: linear-gradient(180deg, rgba(12, 18, 33, 0) 0%, #0C1221 100%);
        }

        &.is-big-celebrity {
            background-position: 95% 50%;

            &:after {
                height: 45px;
            }
        }

        body.rtl-lang & {
            scale: -1 1;
            transform: translate(50%, 100%);
        }
    }

    &__title,&__subtitle {
        max-height: 76px;
        max-width: 100%;
        overflow: hidden;
        @include text(16px, 19px, 700, $white);
        white-space: break-spaces;
    }

    &__subtitle {
        color: $yellow;
        margin-bottom: 12px;
    }

    &__dots {
        display: flex;
        position: absolute;
        bottom: 0;
        inset-inline-end: 8px;

        &.is-main {
            justify-content: center;
            width: 100%;
        }

        &.is-tyson {
            position: relative;
            order: 1;
            bottom: auto;
            inset-inline-end: auto;
            transform: none;
        }

        li{
            width: 21px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        li button {
            font-size: 0;
            width: 8px;
            height: 8px;
            border: 0;
            padding: 0;
            cursor: pointer;
            transform: skewX(-20deg);
            background-color: rgba(233, 233, 233, 0.8);
            background-image: linear-gradient(0deg, $yellow, $yellow);
            background-position: center;
            background-size: 100% 0;
            background-repeat: no-repeat;
            transition: var(--animation-duration) background-size;
        }

        li.slick-active button {
            background-size: 100% 100%;
        }
    }

    @include breakpoint($bp1) {
        &__container {
            background-size: auto 100%;
            padding: 36px 20px;
        }

        &__info {
            align-content: flex-start;
            justify-content: center;
        }

        &__btn {
            max-width: 280px;
        }

        &__dots {
            inset-inline-end: 50%;
            transform: translateX(50%);

            body.rtl-lang & {
                transform: translateX(-50%);
            }

            li {
                margin-right: 8px;
            }
        }

        &__img {
            height: 100%;
            background-size: auto 100%;
            background-position: right;

            body.rtl-lang & {
                background-position: left;
            }
        }
    }

    @include breakpoint($bp2) {
        height: 220px;

        &__container {
            background-size: cover;
            height: 220px;
        }

        &__info {
            max-width: 420px;
        }

        &__title,
        &__subtitle {
            max-height: 66px;

            @include text(28px, 33px);
        }

        &__img {
            inset-inline-start: 50%;
            transform: translate(-50%, 100%);

            body.rtl-lang & {
                transform: translate(50%, 100%);
            }

            .slick-active & {
                transform: translate(-50%, 0);

                body.rtl-lang & {
                    transform: translate(50%, 0);
                }
            }
        }
    }
}
