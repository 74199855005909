$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.balance-filter-block {
  margin-inline: 20px;

  body.rtl-lang & {
    direction: rtl;
  }

  &.is-border {
    border-bottom: 1px solid $blue;
  }
}