$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.auth-deposit-payment {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  overflow: hidden;
  height: 265px;

  &.is-open {
    height: auto;
  }

  &__item {
    border-radius: 2px;
    background-color: #EFF5FD;
    padding: 12px;
    min-height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
}
