$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;

.cashbox-pay-item{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    padding: 4px;
    overflow: hidden;
  
    background-color: $lightGrey;
    border-radius: 2px;
    @include text(16px, 19px, 700, $black);
    
    transition: .2s ease-in-out background-color;
    &.is-active{ background-color: $yellow; }

    img{
        width: 84px;
        height: 21px;
        word-break: break-all;
        @include text(11px, 1);
    }

    span{
        max-width: 100%;
        text-align: center;
        @include ellipsis;
    }
    
    @include breakpoint($bp1){
        height: 50px;
    }
}