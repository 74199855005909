$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.link-contacts {
  padding-top: 4px;

  font-size: 14px;
  line-height: 20px;

  color: #909BAD;

  background-size: 28px;
  background-position: left center;
  background-repeat: no-repeat;

  padding-inline-start: 36px;
  padding-inline-end: 14px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  position: relative;

  body.rtl-lang & {
    background-position: right center;
  }

  &:after {
    content: '';
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNSIgaGVpZ2h0PSIxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNLjA4IDguOUwzLjg1IDUgMCAxLjEuNjEuNSA1IDUgLjYxIDkuNWwtLjUzLS42eiIgZmlsbD0iI0E3QUZCRSIvPjwvc3ZnPg==') center no-repeat;
    background-size: 5px;
    position: absolute;
    top: 10px;
    inset-inline-end: 2px;
    width: 5px;
    height: 9px;

    body.rtl-lang & {
      scale: -1 1;
    }
  }

  span {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;

    color: #FFFFFF;

    margin-top: 4px;
  }

  @include hover(){
    filter: brightness(0) invert(99%) sepia(61%) saturate(7490%) hue-rotate(9deg) brightness(108%) contrast(103%);
  }

}