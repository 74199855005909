$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.home-banner-wrapper {
    position: relative;
    height: 278px;
    @include breakpoint( $bp2 ) {
        height: 326px;
    }
}

.home-banner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 8px 20px 0px;
    background-position: center, left top;
    background-size: cover, cover;
    background-repeat: no-repeat;
    overflow: hidden;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background-repeat: no-repeat;
        background-image: linear-gradient(180deg, rgba(12, 18, 33, 0) 0%, #0C1221 100%);
        background-position: left bottom -1px;
        background-size: 100% 174px;
        z-index: 20;
    }

    &__hero {
        position: absolute;
        top: -8px;
        left: calc(50% + 60px);
        width: 170px;
        height: 290px;
        background-image: url($cdn + "/rabona/main-banner/banner.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;  
        z-index: 10;

        @include breakpoint( $bp1 ) {
            top: -15px;
            left: calc(50% + 70px);
            width: 190px;
            height: 326px;
        }
        @include breakpoint( $bp2 ) {
            top: 0px;
            left: calc(50% + 90px);
        }
    }

    &__form {
        pointer-events: auto;

        .form-label {
            position: relative;
        }
        .form-input {
            width: 280px;
        }
    }

    &__form-btn {
        width: 280px;
        height: 56px;
    }

    &__content {
        position: relative;
        max-width: 460px;
        height: 246px;
        padding-bottom: 28px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        pointer-events: none;
        z-index: 30;
        
        body.rtl-lang & {
            align-items: flex-end;
        }
    }

    &__title {
        width: 280px;
        max-width: 100%;
        text-transform: uppercase;
        margin-bottom: 12px;
        display: flex;
        flex-flow: column;
        p {
            @include text(16px, 19px, 700, $yellow);
            text-transform: uppercase;
        }
        span {
            @include text(28px, 33px, 700, $white);
        }
    }

    &__btn {
        min-width: 280px;
        display: inline-block;
        max-width: 392px;
        pointer-events: auto;
    }

    body.rtl-lang &__btn{
        text-align: center !important;
        font-weight: 900;
        font-family: system-ui;
    }

    @include breakpoint( $bp1 ) {
        &__form {
            .form-label.is-bank-id {
                .form-error {
                    position: absolute;
                    top: 0;
                    left: 280px;
                    width: 222px;
                    height: 48px;
                }
            }
            .form-input{
                flex-shrink: 0;
            }
        }
    }

    @include breakpoint( $bp2 ) {
        &__content {
            height: 325px;
            padding-bottom: 27px;
        }
        &__title{
            width: 100%;
            @include text(58px, 68px);
            max-height: 205px;
        }
        body.rtl-lang &__title {
            width: 100%;
        }
    }

    @include breakpoint($bp3){
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .pay-n-play{
            align-items: center;
        }
    }
}
