$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.limit {
    &__btn-wrap {
        margin-top: 12px;
        text-align: center;
    }

    &__btn {
        line-height: 1.2;
        padding: 11px 8px;
        border: none;  
    }
}