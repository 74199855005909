$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.cashbox-copyright {

  font-size: 10px;
  line-height: 12px;
  color: #A7AFBE;

  
  padding: 0 20px 12px;

  @include breakpoint( $bp1 ){
    padding: 0 40px 20px;
    text-align: center;
  }
}