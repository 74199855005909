$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.popup-block-bg {

  margin: 30px -20px 20px;
  width: 100vw;
  height: 136px;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNpcmNsZSBjeD0iMTAiIGN5PSIxMCIgcj0iMSIgZmlsbD0iI0VGRkIzNyIgZmlsbC1vcGFjaXR5PSIuMzUiLz48L3N2Zz4=');
  background-repeat: repeat;
  background-position: left top;
  background-color: $red;

  position: relative;


  &__text {
    @include text(28px, 34px, bold);
    @include ellipsis;
    text-transform: uppercase;
    text-align: center;
    padding-top: 82px;
    margin: 0 20px;
  }

  &__title {
    @include text(16px, 24px, 400);
    text-align: center;
    color: #FFFFFF;
  }

  &__img {
    width: 80px;
    height: 80px;
    position: absolute;
    display: block;
    top: -10px;
    left: 50%;
    transform: translate(-50%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80px;
    object-fit: contain;
    filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.25));
  }

  @include breakpoint( $bp1 ) {
    margin: 30px auto 20px;
    width: 100%;
  }

}