$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.cashbox-withdrawals{

    display: flex;
    align-items: center;
    justify-content: center;   
    flex-direction: column;
    min-height: 185px;
    padding-top: 20px;
    color: $black;
    
    @include breakpoint($bp1){
        min-height: 372px;
    }
}

