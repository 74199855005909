$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.cup-mark {
  position:absolute;
  bottom:calc(100% - 46px);
  left: 0;

  width: 100%;
  max-width: 300px;

  display: flex;
  align-items: center;

  background-repeat: no-repeat;
  background-size: calc(100% - 2px) 1px;
  background-position: left bottom;
  font-size: 28px;
  z-index: 50;
  min-height: 42px;
  transform: translateY(17px) translateX(353px) !important;

  &.is-center{
    padding-left: 0;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.5) 53.12%, rgba(255, 255, 255, 0) 100%);
    left: auto;
    right: 0;
    padding-right: 164px;
  }


  &__key{
    font-weight: 500;
    margin-right: 4px;
  }
  &__val{
    opacity: 0.5;
    display: flex;
    align-items: center;
  }
  &::before {
    content: "";
    position: absolute;
    width: 7px;
    height: 7px;
    transform: rotate(-45deg);
    left: -15.5px;
    background-color: #576477;
  }
  &__point {
    width: 28px;
    height: 28px;
    margin-right: 2px;
    display: inline-block;

    border-radius: 50%;
    border: 1px solid $white;
    line-height: 28px;
    font-size: 20px;
    text-align: center;
  }
  &.is-complete {
    &::before {
      display: none;
    }
    .cup-mark__key {
      --point: 11px;

      color: $blueDark;
      font-weight: 700;
      background-color: $yellow;
      align-items: center;
      display: inline-flex;
      padding: 2px 17px 3px 34px;
      transform: translateX(-26px);
      clip-path: polygon(var(--point) 0, 100% 0, calc(100% - var(--point)) 100%, 0 100%);

      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='8' viewBox='0 0 11 8' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.1111 1.59613L3.54803 7.61804L0 4.36256L1.06598 3.38448L3.54803 5.66188L9.04513 0.618042L10.1111 1.59613Z' fill='%230B1222'/%3E%3C/svg%3E");
      background-size: 20px 14px;
      background-position: left 12px center;
      background-repeat: no-repeat;

      &::before {
        content: "";
        width: 10px;
        height: 10px;

        left: 10px;
        position: absolute;

        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: var(--icon-i-check);
        filter: brightness(0) saturate(100%) invert(6%) sepia(8%) saturate(5170%) hue-rotate(185deg) brightness(92%) contrast(98%);
      }
    }
    .cup-mark__val {
      display: none;
    }
  }
}

.cup-progress {
  color: #ecfd00;
  background-image: linear-gradient(270deg, #ecfd00 0% 53.13%, #ecfd0000 100%);
  background-position: top;
  background-repeat: no-repeat;
  background-size: calc(100% - 2px) 1px;
  position: absolute;
  bottom: 0;
  height: 1px;
  left: -35px;
  width: 207px;
  z-index: 30;
  &::before{
    content: '';
    display: block;
    width: 100%;
    height: 100px;
    position: absolute;
    left: 0;
    top: 0;
    background-image: url( $cdn + '/rabona/cup-page/m_cup-%20lens_effect.png');
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 30;
    transform: translateY(-50%);
    background-position: 100%
  }
  &__line {
    bottom: 0;
    width: 1px;
    left: 170px;
    position: absolute;

    background-color: $yellow;
    z-index: 10;
  }
}

.rtl-lang .cup-mark__val {
  direction: ltr !important;
}