$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.easter-banner {
  position: relative;
  display: flex;
  justify-content: flex-end;
  text-align: right;
  padding: 20px 12px;
  overflow: hidden;
  min-height: 166px;
  margin: 20px 20px 0;

  @include s {
    padding: 29px 16px;
  }

  @include m {
    padding: 16px 24px;
  }

  @include l {
    padding-right: 48px;
  }

  &__bg {
    background-size: cover;
    background-position: 50% 50%;
    position: absolute;
    inset: 0;

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background-image: radial-gradient(rgba(53, 29, 87, 0), rgb(42, 61, 117)), linear-gradient(90deg, rgba(42, 61, 117, .03), rgb(42, 61, 117));
    }
  }

  &__img {
    position: absolute;
    bottom: -4px;
    left: 5px;
    width: 128px;

    @include s {
      left: 20px;
      width: 142px;
    }

    @include m {
      left: 51px;
      width: 152px;
    }

    @include l {
      left: 108px;
    }
  }

  &__content {
    position: relative;
    max-width: 55%;
    font-weight: 700;
    line-height: 1.1;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;

    @include s {
      max-width: 65%;
    }

    @include m {
      max-width: 75%;
    }
  }

  &__title {
    font-size: 12px;
    color: #fff;
    margin-bottom: 4px;

    @include s {
      font-size: 16px;
    }

    @include m {
      font-size: 24px;
    }
  }

  &__description {
    font-size: 18px;
    color: #ECFD00;
    margin-bottom: 16px;
    line-height: 1;

    @include s {
      font-size: 24px;
      margin-bottom: 12px;
    }

    @include m {
      font-size: 36px;
    }

    span {
      @include s {
        display: block;
      }
    }
  }

  &__button {
    margin-top: auto;
    font-weight: 400;
  }

  .btn-stroke {
    min-width: auto;
  }
}
