$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.home-mod {
    width: 100vw;
    box-sizing: border-box;    
    padding: 20px 0 14px;
    background: $red;
    @include text(16px, 19px, 400, $white);
    margin-left: -20px;
    @include breakpoint($bp3){
        width: 320px;
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 20px;
        position: relative;
    }
    &__info {
        @include breakpoint($bp1){
            width: calc(100% - 130px);
        }
        @include breakpoint($bp2){
            width: calc(100% - 450px);
        }
        @include breakpoint($bp3){ 
            width: auto;
            margin-top: 30px;
        }
    }
    &__head {
        display: flex;
        flex-wrap: wrap;
        @include breakpoint($bp1){
            display: inline;
        }
        @include breakpoint($bp3){ 
            display: flex;
        }
    }
    &__title {
        max-width: calc(100% - 130px);
        font-weight: bold;
        text-transform: uppercase;
        margin-right: 4px;
        margin-bottom: 4px;
        @include ellipsis;
        @include breakpoint($bp1){
            display: inline;
        }
        @include breakpoint($bp2){ 
            white-space: normal;
        }
        @include breakpoint($bp3){ 
            white-space: nowrap;
        }
    }
    &__time {
        opacity: 0.6;
        display: inline;        
    }
    &__champ {
        width: 100%;
        opacity: 0.6;
        padding-right: 100px;
        margin-bottom: 4px;
        display: block;
        @include breakpoint($bp1){
            width: auto;
            display: inline;
            position: relative;
            padding-left: 10px;
            padding-right: 0;
            &:before {
                content: ',';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
            }
        }
        @include breakpoint($bp3){
            display: block;
            padding-left: 0;
            margin-top: 4px;
            &:before {
                content: none;
            }
        }
    }
    &__teams {
        width: 100%;
        margin: 4px 0 9px;          
        @include breakpoint($bp1){      
            margin: 6px 0 9px;
        }
        @include breakpoint($bp2){  
            margin: 6px 0 22px;
        }
        @include breakpoint($bp3){ 
            margin: 6px 0 9px;
        }
    }
    &__score {
        display: inline;
        font-weight: bold;
        margin-left: 4px;
    }
    &__team-logos {
        display: flex;
        position: absolute;
        top: -8px;
        right: 22px;
        @include breakpoint($bp2){            
            position: relative;
            top: auto;
            right: auto;
            order: 1;
        }
        @include breakpoint($bp3){  
            position: absolute;
            top: -8px;
            right: 20px;
        }
    }
    &__team-logo {
        width: 48px;
        height: 48px;
        background: black;
        border-radius: 50%;
        object-fit: cover;
        & + & {
            margin-left: -4px;
        }             
    }
    &__bets {
        width: 280px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
        @include breakpoint($bp2){ 
            margin-top: 5px;
        }
        @include breakpoint($bp3){ 
            margin-bottom: 44px;
        }
    }
    &__bet {
        width: 88px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background: rgba(255, 255, 255, 0.15);
        border: 1px solid rgba(255, 255, 255, 0.5);        
        border-radius: 2px;
        padding: 0 5px;
        span {
            @include ellipsis;
            margin-left: 4px;
            color: $white;
        }  

        &.hasIncreasedPrice,
        &.hasDecreasedPrice  {
            &::before {
                content: '';
                position: absolute;
                width: 8px;
                height: 8px;
                border-style: solid;
                right: -1px;
            }
        }
        &.hasIncreasedPrice {
            &::before {
                top: -1px;
                border-width: 0 8px 8px 0;
                border-color: transparent $yellow transparent transparent;
            }
        }
        &.hasDecreasedPrice {
            &::before {
                bottom: -1px;
                border-width: 0 0 8px 8px;
                border-color: transparent transparent $white transparent;
            }
        }
    }


}