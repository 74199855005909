$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.modal-global {
  margin: 34px;

  @include breakpoint($bp440) {
    margin: 40px auto;
  }

  &.modal-body {
    max-width: 440px;
    padding: 20px;
    width: auto;
    min-height: auto;
    display: grid;
    row-gap: 20px;
  }

  &__list {
    display: grid;
    row-gap: 12px;
    counter-reset: counter;

    li {
      position: relative;
      counter-increment: counter;
      border: none;
      padding-inline-start: 20px;
      height: auto;
      color: #A5AFC0;
      text-transform: none;
      font-size: 16px;
      text-align: start;

      &:before {
        content: counter(counter)'.';
        position: absolute;
        top: 1px;
        inset-inline-start: 0;
        color: #A5AFC0;
        font-size: 16px;
        line-height: 1;
      }
    }
  }

  &__title {
    font-size: 24px;
    line-height: 1;
    padding-bottom: 13px;
    border-bottom: 1px solid #273242;
    text-align: center;
  }

  &__link {
    margin-top: 12px;
  }
}
