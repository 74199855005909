$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.cash-crab-btn {
    width: 35px;
    height: 40px;
    background: url($cdn + '/casinia/crab/icon-crab-free-vertical.png') no-repeat center / contain;
    transition: transform .15s;
    &:hover {
        transform: translateY(-2px);
    }
    .header & {
        margin-right: 16px;
    }
}