$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.cash-crab-prize-item {

  margin: 12px 5px;
  width: 168px;

  --prize-color-2: #66B429;
  --prize-color: rgba(48, 186, 113, 1);
  &.is-elephant {
    --prize-color-2: #FA9AB8;
    --prize-color: #EF749F;
  }
  &.is-duck {
    --prize-color-2: #FBCA05;
    --prize-color: #FBCA05;
  }
  &.is-bear {
    --prize-color-2: #FBC696;
    --prize-color: #FBC696;
  }
  &.is-octopus {
    --prize-color-2: #F85EE2;
    --prize-color: #E36ADC;
  }
  
  &__name {
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    text-transform: capitalize;
    color: white;
    text-align: center;
    margin-bottom: 2px;
  }

  &__body {
    width: 100%;

    font-weight: bold;
    font-size: 11px;
    line-height: 15px;
    text-align: center;
    color: #7E6294;

    padding: 150px 4px 0;
    box-sizing: border-box;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    
    background: var(--bg) no-repeat center top / 152px, linear-gradient(180deg, rgba(91, 187, 221, 0) -22.35%, rgba(91, 187, 221, 0.5) 54.87%, var(--prize-color-2) 100%);
    border-radius: 6px;
    filter: drop-shadow(0px -2px 30px rgba(0, 0, 0, 0.06));
  }

  &__amount {
    display: flex;
    align-items: center;
    span {
      font-weight: 900;
      font-size: 32px;
      line-height: 34px;
      text-transform: uppercase;
      color: #ECFD00;
      text-shadow: 0px 0px 12px rgba(245, 246, 249, 0.35), 0px 0px 8px rgba(0, 0, 0, 0.3);
    }
  }
  &__prize {
    font-weight: 900;
    font-size: 19px;
    line-height: 150%;
    letter-spacing: 0.05em;
    color: #fff;
    text-shadow: 0px 3px 11px rgba(0, 0, 0, 0.364);
    text-transform: capitalize;
    width: 100%;
    padding-bottom: 5px;
  }
}