$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.progress-bar {
    width: 100%;
    height: 6px;
    background: $black;
    position: relative;
    margin-top: 6px;
    
    body.rtl-lang & {
        direction: rtl;
    }

    &__active {
        position: absolute;
        width: 10px;
        height: 100%;
        background: $yellow;
        &::after {
            content: '';
            inset-inline-end: 0;
            top: -2px;
            width: 1px;
            height: 10px;
            position: absolute;
            background: $yellow;
        }
    }
}