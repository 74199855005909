$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.layout-history {
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline-end: 20px;
    margin-top: 12px;
    border-bottom: 1px solid $blue;
    padding-bottom: 8px;
    body.rtl-lang & {
      direction: rtl;
    }
  }
  &__body {
    width: 100%;
    padding-bottom: 40px;
  }
  &__btn {
    display: table;
    margin: 32px auto 0;
  }
}