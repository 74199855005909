$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.crab-body-prizes {
    padding: 0 !important;
}

.cash-crab-guide {
    background: #020F20;
    ;
    box-sizing: border-box;
    align-self: baseline;
    position: relative;
    padding: 56px 5px;
    margin: 0 auto;
    width: 100%;
    &__title {
        font-weight: 900;
        font-size: 32px;
        line-height: 34px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #FFFFFF;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    }
    &__prizes {
        display: flex;
        justify-content: center;
        margin: 25px 0 35px 0;
        flex-wrap: wrap;
    }
    &__section {
        display: flex;
        justify-content: center;
        margin: 20px auto;
        flex-direction: column;
    }
    &__box {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.01em;
        text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
        margin: 10px 0;
        &.is-1 {
            width: 100%;
        }
        &.is-2 {
            width: 100%;
            flex-direction: row-reverse;
        }
        &.is-3 {
            width: 100%;
        }
        img {
            margin: 0 20px 0 0;
        }
        a {
            color: white;
            font-weight: 700;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
        &.has-column {
            flex-direction: column;
            img {
                margin: 0 0 3px;
            }
        }
    }
}