$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.crab-slider-body {
  padding: 0 !important;
}
.crab-body-slide {
  padding: 0 20px !important;
}
.crab-body-win {
  padding: 0 !important;
  background: none !important;
}
.crab-body-lose {
  padding: 0 !important;
  background: none !important;
  display: contents !important;
}
.cash-crab-modal {
  padding: 0;
}
.cash-crab-body-slide {
  padding: 0 20px !important;
}
.cash-crab-popup-container {
  position: relative;
  box-sizing: border-box;
  .icon-i-close-crab {
    display: none;
  }
  & .cash-crab-close {
    top: 0px;
    right: 5px;
    z-index: 10;
  }
  & .cash-crab-close-slide {
    top: 17px;
    right: 1px;
    z-index: 10;
    width: 15px;
    height: 15px;
  }
  & .cash-crab-close-slider {
    width: 15px;
    height: 15px;
    top: 17px;
    right: 21px;
    z-index: 10;
  }
  &.cash-crab-slider {
    width: 100%;
    height: 100%;
  }
  &.cash-crab-lose {
    background: #162334;
    width: 354px;
    min-height: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
  }
}
.cash-crab-popup {
  overflow: hidden;
  position: relative;
  &.cc-slide-container {
    height: 100%;
  }
  &__slide {
    position: relative;
    flex-direction:column !important;
    justify-content:flex-start !important;
    padding: 0 0 20px 0;
    margin-bottom: 0;
    &.is-p6{
      padding: 20px 0;
    }
  }
  &__title {
    font-weight: normal;
    font-size: 24px;
    text-align: left;
    color: #fff;
    padding: 0 40px 15px 40px;
    margin: 0;
    &.title-slide {
      padding: 0 20px 15px 20px;
    }
    &.title-lose {
      text-align: center;
      font-size: 38px;
      padding: 0 0 25px 0;
    }
  }
  &__description {
    margin: 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 15px;
    text-align: left;
    color: #A5AFC0;;
    padding: 0 40px;
    &.desc-slide {
      padding: 0 20px;
    }
  }
  &__box{
    text-align: center;
    color: #8C959C;
    margin-top: 0px;
  }
  &__pic-first{
    margin-top: 50px;
  }
  &__img1{
    margin: 40px auto 0;
    max-width: 90%;
  }
  &__img2 {
    margin: 40px auto 0;
    width: 80%;
  }
  &__btns {
    position: relative;
    bottom: 0;
    z-index: 10;
    margin: 0 auto;
    & button {
      box-sizing: border-box;
      min-width: 280px;
    }
    &.btns-cc-popup {
      bottom: 40px;
    }
    &.btn-slide {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
    }
    &.is-c{
      display: flex;
      justify-content: center;
    }
  }
  &__btn {
    min-width: 180px;
  }
  &__row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    &.row-cash-crab {
      margin: 0 auto;
    }
  }
  &__item {
    position: relative;
    width: 130px;
    text-align: center;
    padding-top: 120px;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    background-position: center 25px;
    background-repeat: no-repeat;
    &__label {
      position: absolute;
      top: 0;
      left: -10px;
      z-index: 30;
      .game-item:not(.linda-has-label) &{ display:none !important;}
    }
    &.is-1 {
      background-image: url($cdn + '/rabona/crab/icon1-min.png');
      background-size: 130px;
    }
    &.is-2 {
      background-image: url($cdn + '/rabona/crab/icon2-min.png');
      background-size: 80px;
      background-position-y: 20px
    }
    & span {
      display: inline-block;
      background: #FF0000;
      box-shadow: 0px 3.77778px 0px #990101;
      border-radius: 4px;
      padding: 0 8px;
      height: 34px;
      font-weight: 900;
      font-size: 20px;
      line-height: 34px;
      text-transform: uppercase;
    }
  }
  & .cash-crab-slider-txt {
    position: relative;
    height: -webkit-fill-available;
  }
  & .cash-crab-slider-img {
    position: relative;
    height: 270px;
    min-height: 270px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
    & img {
      display: block;
      padding: 0;
      margin: 0 auto;
    }
    & .cash-crab-popup {
      &__img1 {
        margin-top: 60px;
      }
      &__row {
        margin-top: 0;
      }
    }
  }
  &__symbol {
    font-weight: 900;
    font-size: 36px;
    line-height: 39px;
    color: #fff;
    margin: 0 17px;
    height: 10px;
  }
  & .cash-crab-slide-center {
    position: relative;
    height: 275px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 15px 0;
  }
  &__timer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin: 130px auto;
    & span {
      text-align: center;
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      color: #fff;
      margin: 0 0 25px 0;
    }
    & strong {
      font-weight: 900;
      font-size: 42px;
      line-height: 51px;
      text-align: center;
      color: #F4F2FB;
      text-shadow: 0px 1px 2px #4372DE;
    }
    
  }
  & .swiper-pagination {
    position: relative;
    width: max-content;
    height: 2px;
    width: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
    margin: 12px auto 0;
  }
  & .swiper-pagination-bullet {
    display: block;
    opacity: 1;
    width: 33%;
    height: 2px;
    margin: 0 4px;
    background-color: #243750;
    &.swiper-pagination-bullet-active {
      opacity: 1;
      width: 33%;
      height: 2px;
      background: #ECFD00;
    }
  }
}

@media screen and (min-width: 540px) {
  .crab-body-slide,
  .crab-slider-body,
  .cash-crab-body-slide {
    max-width: 564px !important;
    min-height: 476px !important;
    margin: 40px auto !important;
    padding: 25px 40px 36px 40px !important;
    box-sizing: border-box;
    & .popup-close-btn {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 0;
      right: 0;
      background-size: cover;
      body.rtl-lang & {
        right: inherit;
        left: 0;
      }
    }
    .cash-crab-close-slide,
    .cash-crab-close-slider {
      display: none;
    }
    .icon-i-close-crab {
      display: block;
      filter: invert(100%);
      background-image: var(--icon-i-close);
      top: 5px !important;
      right: -10px !important;
      z-index: 10;
    }
  }
  .cash-crab-modal {
    padding: 50px 0;
  }
  .cash-crab-popup {
    &__slide {
      margin-bottom: 0;
    }
    &__title {
      font-size: 38px;
      text-align: center;
      &.title-slide {
        padding: 0 25px 15px 25px;
      }
    }
    &__description {
      text-align: center;
      &.desc-slide {
        padding: 0 25px;
      }
    }
    & .cash-crab-slider-img {
      & .cash-crab-popup {
        &__img2 {
          width: 60%;
        }
      }
    }
    &__symbol {
      margin: 0 40px;
    }
    &__row {
      margin-top: 10px;
    }
    &__img1{
      width: 70%;
      padding: 30px 0 0 0;
    }
    &__img2 {
      margin-top: 15px;
    }
    &__btns {
      position: relative;
      left: 0;
      transform: translateX(0);
      button {
        margin: 0 !important;
      }
      &.btn-deposit,
      &.btn-fail {
        bottom: 0;
        margin: 0 auto;
      }
      &.btn-play {
        bottom: 0;
      }
      &.btn-slide {
        position: relative;
        margin: 0 auto;
        bottom: 0;
        left: 0;
        transform: translateX(0);
      }
    }
    &__timer {
      margin: 58px auto;
    }
  }
}

@media screen and (max-width: 320px) {
  .cash-crab-popup-container {
    &.cash-crab-lose {
      width: 320px;
    }
  }
  .cash-crab-popup {
    &__title {
      &.title-slide {
        padding: 0 0 15px 0;
      }
      &.title-slider {
        padding: 0 20px 15px 20px;
      }
    }
    &__description {
      &.desc-slide {
        padding: 0;
      }
      &.desc-slider {
        padding: 0 20px;
      }
    }
    &__item {
      width: 130px;
      padding-top: 120px;
      &.is-1 {
        background-size: 120px;
      }
      &.is-2 {
        background-size: 70px;
        background-position-y: 20px
      }
    }
    &__symbol {
      margin: 0 5px;
      height: 20px;
    }
  }
}