$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.modal-body {

  width: 100%;
  min-height: 100%;
  background: #192333;
  padding: 32px 20px;
  display: block;

  transition: all .2s;
  position: relative;

  &.is-migration {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &.gdpr-modal {
    max-width: 440px;
    box-shadow: none;
    background: transparent;
    margin: auto;
    padding: 40px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.optout-modal {
    max-width: 292px;
    margin: 34px auto;
    padding: 20px;
    min-height: auto;
    display: grid;
    row-gap: 20px;

    @include breakpoint($bp1) {
      max-width: 440px;
    }

    .modal-title {
      font-size: 24px;
      margin: 0;

      &.has-line {
        padding-bottom: 12px;
        border-bottom: 1px solid #273242;
      }
    }

    .modal-text {
      text-align: center;
      margin-bottom: 0;
    }

    .btn-promo {
      margin-bottom: 0;
    }
  }

  @include breakpoint($bp1 ) {
    margin: 40px auto;
    max-width: 472px;
    min-height: auto;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 0 12px rgba(29, 37, 52, 0.4);
    padding: 27px 40px;

    &.is-wide {
      max-width: 472px;
    }
  }

  @include breakpoint($bp3 ) {
    &.is-wide {
      max-width: 976px;
    }
  }

  .btn-promo {
    margin-bottom: 12px;
  }

  .ng-enter & {
    transition: all .2s;
    transform: translateY(30px);
    opacity: 0;
    z-index: 9999;
  }

  .ng-enter.ng-enter-active & {
    opacity: 1;
    transform: none;
  }

  .ng-leave & {
    transition: all .2s;
    z-index: 9999;
    opacity: 1;
    transform: none;
  }

  .ng-leave-active {
    z-index: 9999;
    opacity: 0;
    transform: translateY(30px);
  }

  .preloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($darkBlue, .5);
    z-index: 100;
  }
}
