$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }
  