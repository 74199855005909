$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.header-close {

  position: absolute;
  right: 0;
  width: 48px;
  height: 48px;
  background-position: center;
  background-repeat: no-repeat;
  filter: invert(74%) sepia(15%) saturate(245%) hue-rotate(181deg) brightness(92%) contrast(90%);

  @include hover(){
    opacity: .8;
  }

}