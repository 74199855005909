$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
linda-search {
  position: relative;

  &:has(.game-providers) {
    overflow: hidden;
  }
  &:has(.game-providers.is-opened),
  &:has(.game-providers.ng-animate),
  &:has(.has-search-mode){
    overflow: unset;
  }
}
