$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.collection-list {
    .tab {
        height: 48px;
        white-space: nowrap;
        overflow: auto;
        margin-top: 12px;        
    }
    &__items {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 44px;

        body.rtl-lang & {
            direction: rtl;
        }
    }
    &__empty {
        display: none;
        &:nth-child(1) {
            display: block;
        }
        .btn-promo {
            position: absolute;
            bottom: 20px;
            z-index: 9;
            margin: 0 auto;
            width: 100%;
        }
    }
    &__item {
        width: 232px;
        min-height: 402px;
        position: relative;
        background-image: url($cdn + '/rabona/cards-page/pattern-collections.png');
        background-size: 115px auto;
        background-repeat: repeat;
        background-color: $red;
        border-radius: 3px;
        margin-left: 34px;
        margin-right: 34px;
        margin-bottom: 40px;
        @include breakpoint( $bp1 ) {
            width: 272px;
            margin-left: 134px;
            margin-right: 134px;
        }
        @include breakpoint( $bp2 ) {            
            margin-left: 34px;
            margin-right: 34px;
        }
        @include breakpoint( $bp3 ) {  
            width: 252px;
        }
        &.is-status-inactive {
            background-color: $lightBlue;
            .collection-list__item-img,
            .collection-list__label-img {
                filter: grayscale(1);
            }
            .collection-list__label {
                background: $grey;            
            }
            .collection-list__label-container::after {
                background: $grey;
            }
            .collection-list__price {
                color: $grey;
            }
        }
        &:last-of-type {
            margin-bottom: 20px;
        }

        body.rtl-lang & {
            direction: ltr;
        }
    }
    &__item-bg {
        width: 100%;
        min-height: 402px;
        position: relative;      
        z-index: 2; 
        &::before,
        &::after {
            content: '';
            position: absolute;
            width: 188px;
            height: 278px;
            background-image: url($cdn + '/rabona/cards-page/card-close-mob.png');
            background-size: cover;
        }
        &::after {
            top: 25px;
            right: 5px;
            transform: rotate(8deg);
        }
        &::before {
            top: 18px;
            left: 5px;
            transform: rotate(-8deg);
        }       
    }
    &__item-img {
        width: 235px;
        height: 290px;
        background-size: cover;
        position: absolute;
        top: -16px;
        right: 0;
        z-index: 3;
        @include breakpoint( $bp1 ) {
            right: 18px;
        }
        @include breakpoint( $bp3 ) {
            right: 8px;
        }
    }
    &__item-container {
        width: 100%;
        height: 250px;
        padding: 0 16px 16px;
        box-sizing: border-box;
        background-image: url($cdn + '/rabona/cards-page/down-card.png');
        background-size: cover;
        border-radius: 0 0 3px 3px;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 3;
    }
    &__label-container {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            z-index: -1;
            left: -21px;
            bottom: -4px;
            width: 5px;
            height: 15px;
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 12px);
            background-color: $yellow;
            filter: brightness(.5);
        }
    }
    &__label {
        @include text(20px, 20px, bold, $black);
        text-transform: uppercase;  
        position: relative;     
        display: flex;
        align-items: center;
        width: 237px;
        height: 55px;
        padding: 5px 10px 5px 0;
        background-color: $yellow;
        transform-origin: left;
        transform: skewY(-20deg);
        box-shadow: 3px 3px 0px 0px rgba(12, 18, 33, 0.2);
        margin-top: 68px;
        margin-left: -21px;
        z-index: 1;    
        span {
            width: 160px;
            margin-inline-start: 4px;
            overflow: hidden;
            max-height: 40px;

            body.rtl-lang & {
                direction: ltr;
            }
        }
    }
    &__label-img {
        width: 58px;
        height: 58px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: skewY(20deg);
        object-fit: contain;
        filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.25));
    }
    &__content {
        height: 134px;
    }   
    &__price {
        @include text(39px, 45px, bold, $yellow);
        text-align: center;
        margin-top: 10px;

        body.rtl-lang & {
            text-align: center;
        }
    }
    &__complete {
        @include text(20px, 24px, 700, $yellow);
        position: relative;
        text-transform: uppercase;
        padding-left: 30px;

        &-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;

            @include breakpoint($bp1){
                height: 55px;
            }
        }

        &::before {
            content: '';
            display: block;
            background-image: var(--icon-choose);
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            background-size: 80%;
            width: 20px;
            height: 20px;
            left: 4px;
            top: 2px;
        }
    }
    &__btn {
        width: 100%;
        padding: 0 5px;
        margin-top: 18px;
    }
}