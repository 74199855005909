$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.btn-back-popups {

  display: flex;
  align-items: center;

  height: 48px;
  font-size: 16px;
  line-height: 120%;
  color: #A7AFBE;

  padding-left: 24px;

  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzLjUgNC44bC01IDUuMSA1IDUuMi0xIDEuMS02LTYuMyA2LTYuMSAxIDF6IiBmaWxsPSIjQTdBRkJFIi8+PC9zdmc+') left center no-repeat;

  @include hover(){
    opacity: .8;
    text-decoration: none;
  }

}