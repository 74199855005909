$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.tournament-how-to {
	padding: 0 17px;
	margin-bottom: 60px;

	@include breakpoint($bp1) {
		padding: 0 20px;
	}

	@include breakpoint($bp2) {
		padding: 0 24px;
	}

	&__title {
		text-transform: uppercase;
		text-align: center;
		font-size: 20px;
		line-height: 23px;
		margin-bottom: 12px;
	}

	&__subtitle {
		margin-bottom: 24px;
		font-size: 18px;
		line-height: 150%;
		text-align: center;
	}

	&__list {
		@include breakpoint($bp3) {
			display: grid;
			grid-template: repeat(2, 1fr) / repeat(2, 1fr);
			grid-auto-flow: column;
		}
	}

	&__item {
		margin-bottom: 16px;

		@include breakpoint($bp3) {
			padding-right: 10px;
		}
	}

	&__item-text {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 16px;
		font-size: 18px;
		line-height: 150%;
		
		@include breakpoint($bp1) {
			align-items: center;
			
		}
		@include breakpoint($bp3) {
			align-items: flex-start;
		}
		
		&::before {
			content: '';
			flex: 0 0 auto;
			width: 64px;
			height: 64px;
			border-radius: 2px;
			background-color: #162334;
			background-size: 30px 30px;
			background-repeat: no-repeat;
			background-position: center;
		}
	}
}