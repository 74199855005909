$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.page-verification-alert {
  position: relative;
  width: 100%;
  min-height: 28px;
  display: none;
  padding: 4px 10px 4px 18px;
  background-color: #cc0034;
  color: #ffffff;
  align-items: center;
  justify-content: center;
  z-index: 100;

  &.is-verified {
    background-color: #00c400;
  }

  &.is-verification-changed {
    display: flex;
  }

  &__text {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      width: 20px;
      height: 20px;
      display: block;
      content: '';
      background: url($cdn + '/rabona/verification/non-verified.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin-inline-end: 8px;
      flex: 0 0 20px;

      .is-verified & {
        background: url($cdn + '/rabona/verification/verified.svg') no-repeat;
      }
    }
  }

  &__link {
    text-decoration: underline;
    color: #ffffff;
    font-weight: 600;
    margin: 0 4px;
  }

  &__icon {
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    background-position: center;
    margin-inline-start: 8px;
    cursor: pointer;
    filter: invert(100%);
    flex-shrink: 0;

    &.is-arrow {
      background-size: contain;
      background-image: var(--icon-arrow);

      body.rtl-lang & {
        scale: -1 1;
      }
    }

    &.is-close {
      background-size: 18px;
      background-image: var(--icon-i-close);
    }
  }
}