$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.cup {
  width: 391px;
  position:absolute;
  bottom: 10px;
  left: calc( 50% - 191px);
  z-index: 20;
  background-image: var(--icon-cup_l) , radial-gradient(46.2% 46.2% at 50% 50%, rgba(255,255,255,0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100% auto , 100% 70px;

  transform: translate3d(0,0,0);
  & * {
    transform: translate3d(0,0,0);
  }

  &__title{
    font-size: 28px;
    text-align: center;
    text-transform: uppercase;
    color: $yellow;
    overflow: hidden;

    body.rtl-lang & > span {
       direction: ltr;
       display: inline-block;
    }
  }
  &__prize{
    font-weight: bold;
    font-size: 32px;
    line-height: 38px;
    overflow: hidden;
    text-align: center;
    text-transform: uppercase;
    color: $yellow;
    margin-bottom: 12px;
  }

  body.rtl-lang &__title,
  body.rtl-lang &__prize {
    text-align: center;
  }

  &__wrap{
    width: 267px;
    height: 283px;
    position:relative;
    margin:0 auto;

    &:not(.linda-img-loaded){
      overflow: hidden;

      background-image: url( $cdn + '/rabona/cup-page/m_cup_glass_frame.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left top;
    }
    &::after {
      content: "";
      width: 1px;
      background-color: #576477;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      transform: translateX(170px);
    }
  }

  &__back{
    position:absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    z-index: 22;

    min-width: 328px;
    min-height: 566px;
    transform-origin: 0 0;
    transform: scale(.5);

    .linda-img-loaded &{ background-image: url( $cdn + '/rabona/cup-page/m_cup_glass.png'); }
  }

  &__front{
    position:absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 23;
    overflow: hidden;
  }


  &__front-img{
    position:absolute;
    bottom: 0;
    left: 0;
    width: 328px;
    height: 566px;
    transform-origin: 0 100%;
    transform: scale(.5);
    background-size: cover !important;
    z-index: 24;
    .linda-img-loaded &{ background-image: url( $cdn + '/rabona/cup-page/m_cup_gold.png' ); }

  }
  &__info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-bottom: 20px;

    position: relative;
    z-index: 40;
  }
  &__info-text {
    opacity: 0.5;
  }
  &__info-icon {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    text-align: center;
    border: 1px solid #ECFD00;
  }
}
