$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
body.rtl-lang {
    .easter-box{
        &__num,
        &__panel-label,
        &__panel-title,
        &__panel-text,
        &__panel-info,
        &__row-title,
        &__row-bonus,
        &__row-divider {
        text-align: center;
        }
        &__requirements-item-descr{
        text-align: left;
        }
    }
}