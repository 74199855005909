$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.layout-promo-page {
  padding-top: 12px;
  flex-grow: 1;
  .layout-promo-page__tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    body.rtl-lang & {
      direction: rtl;
    }

    &.is-reverse {
      flex-direction: row-reverse;
    }
    a + a {
      margin-left: 0;
    }
  }
}
