$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.limit-block {
    padding: 20px 5px;
    width: 248px;
    margin: 12px auto 0; 
    background-color: $blue; 
    text-align: center;
  
    &__icon {
      width: 20px;
      height: 20px;
      margin: 0 auto 16px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: var(--icon-selfcontrol-limit); 
    }
  
    &__title {
      margin-bottom: 16px;
      @include text(18px, 1.2, 700); 
      @include ellipsis; 
    }
  
    &__value {
      margin-bottom: 16px;
      @include text(30px, 1, 700); 
      @include ellipsis;
    }
  
    &__text {
      margin-top: 8px;
      @include text(10px, 1.5, normal, #eff5fd); 
    }
}
  