$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.form-radio{
  display: none;

  &__label {
    display: flex;
    align-items: center;
    cursor: pointer;

    //&::before{
    //  content: '';
    //  display: block;
    //  width: 16px;
    //  height: 16px;
    //
    //  box-sizing: border-box;
    //
    //  background: #FFFFFF;
    //  //box-shadow: 0px 1px 4px rgba(105, 43, 142, 0.28);
    //  border-radius: 10px;
    //}
  }

  &__box {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    background: #293241;
    border: 1px solid #2D394C;
    box-sizing: border-box;
    border-radius: 50%;
    margin-inline-end: 15px;
  }

  &__text {
    font-size: 14px;
    line-height: 120%;
    color: #FFFFFF;
  }

  //&:hover + label .form-radio__box{
  //  box-shadow: 0px 5px 15px rgba(136, 51, 188, 0.2);
  //}

  &:checked + label .form-radio__box {
    background: $yellow;
    border-color: $yellow;

    &::before{
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      margin: 4px;
      background: #192333;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
      border-radius: 50%;
    }
  }
  &:disabled + label .form-radio__text{color: #596475;}
  &:disabled:checked+label .form-radio__box {
    background: $blueGray;
    border-color: $blueGray;
  }
  &:disabled:not(:checked) + label .form-radio__box { background: transparent; box-shadow: 0px 1px 4px rgba(105, 43, 142, 0.28); }
  &:disabled + label { cursor:default;}


}

