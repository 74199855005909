$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.home-banner.is-oktoberfest {
  background-size: 0px, cover;
  .home-banner__hero {
    width: 100%;
    height: 300px;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: right -250px top -30px;
    @include breakpoint($bp1) {
      background-position: right -180px top -30px;
      width: 100%;
      height: 300px;
      top: 0;
    }
    @include breakpoint($bp2) {
      width: 100%;
      height: 380px;
      background-position: left -80px top -40px;
      top: 0;
    }
    @include breakpoint($bp3) {
      width: 100%;
      height: 380px;
      background-position: left 20px top -40px;
      top: 0;
    }
  }
}

.home-ao-banner{
  height: 166px;
  position: relative;
  overflow: hidden;
  background-position: right -75px top -55px;
  background-size: auto 320px;
  @include breakpoint($bp1) {
    height: 182px;
    background-position: right -185px top -55px;
    background-size: auto 360px;
  }
  @include breakpoint($bp2) {
    background-position: right -155px top -70px;
    background-size: auto 400px;
  }
  &::before{
    width: 100%;
    height: 100%;
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    background: linear-gradient(90deg, rgba(0, 145, 210, 0.03) 0%, #0091D2 100%);
    filter: blur(0.5px);
  }
  &-wrap{
    padding: 24px 20px 0;
    display: block;
    &.is-clf{
      padding-top: 0;
      padding-bottom: 24px;
    }
    &:empty{
      display: none;
    }
  }
  &__logo{
    display: none;
    @include breakpoint($bp1) {
      display: block;
      width: 156px;
      height: 156px;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      left: -8px;
      bottom: 10px;
    }
    @include breakpoint($bp2) {
      width: 212px;
      height: 212px;
      left: -16px;
      bottom: -13px;
    }
    @include breakpoint($bp3) {
      left: 25px;
    }
  }
  &__hero{
    height: 180px;
    width: 240px;
    background-size: auto 180px;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: -45px;
    @include breakpoint($bp1) {
      height: 182px;
      background-size: auto 186px;
      left: 60px;
    }
    @include breakpoint($bp2) {
      left: 145px;
    }
    @include breakpoint($bp3) {
      left: 170px;
    }
  }
  &__title{
    color: #FFF;
    text-align: right;
    text-shadow: 0px 8px 10px rgba(0, 0, 0, 0.20);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    text-transform: uppercase;
    margin-bottom: 4px;
    @include breakpoint($bp1) {
      font-size: 18px;
    }
    @include breakpoint($bp2) {
      font-size: 24px;
    }
  }
  &__text{
    color: #ECFD00;
    text-align: right;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;
    @include breakpoint($bp1) {
      font-size: 30px;
    }
    @include breakpoint($bp2) {
      font-size: 36px;
    }
  }
  &__content{
    padding: 17px;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: auto;
    position: relative;
    align-items: flex-end;
    @include breakpoint($bp1) {

    }
  }
  &__btn{
    margin-top: auto;
  }
}
