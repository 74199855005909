$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.form-tooltip {

  display: none;

  background: #0C1221;
  color: #A7AFBE;
  font-size: 10px;
  line-height: 15px;
  padding: 8px 20px;

  & > * {
    display: inline;
    margin-right: 4px;
    padding-left: 12px;
  }

  .has-tooltip.ng-invalid:focus ~ & {
    display:block;
  }

  &__min,
  &__number,
  &__low,
  &__up {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTYgNi42bDIuNyAyLjcuNi0uNkw2LjYgNmwyLjctMi43LS42LS42TDYgNS40IDMuMyAyLjdsLS42LjZMNS40IDYgMi43IDguN2wuNi42TDYgNi42eiIgZmlsbD0iI0JDMUYzOSIvPjwvc3ZnPg==');
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: left center;
  }

  .ng-valid-min ~ & .form-tooltip__min,
  .ng-valid-number ~ & .form-tooltip__number,
  .ng-valid-low ~ & .form-tooltip__low,
  .ng-valid-up ~ & .form-tooltip__up{
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMC44IDMuNkw0LjYgMTAgMS4yIDYuNWwxLTEgMi40IDIuNCA1LjItNS4zIDEgMXoiIGZpbGw9IiMyNkMwMDAiLz48L3N2Zz4=');
    color: white;
  }

}

body.rtl-lang .form-tooltip {
  text-align: right;
  direction: rtl;
}