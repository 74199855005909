$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.title {

  display: inline-block;

  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;

  color: #FFFFFF;

  margin: 12px 20px;
  padding-bottom: 3px;

  border-bottom: 2px solid #CC0034;

  background-repeat: no-repeat;
  background-position: right center;

  &.is-left {
    margin-left: 0;
  }

  &__category{
    text-align: center;
    font-size: 28px;
    line-height: 33px;
    text-transform: uppercase;
    margin-top: 8px;
  }

  body.rtl-lang &__category {
    text-align: center !important;
  }

}