$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
//- widget submit button
.quick-reg-widget-btn {
  width: 100%;
  max-width: 100%;
  transition: background .2s;

  &:after {
    content: attr(name);
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    font-family: inherit;
    text-align: center;
    line-height: inherit;
    width: 100%;
    overflow: hidden;
  }

  &.is-empty {
    background: #a7afbe;
  }
}



.quick-accept-btn {
  background: linear-gradient(180deg, #A0FF00 0%, #68F284 95.03%);
  box-shadow: 0px 2px 0px #38B751;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border: 0;
  outline: 0;
  width: fit-content;
  min-width: 84px;
  margin: 0 auto;

  &:after {
    content: attr(name);
    white-space: nowrap;
    //font-family: $fontFamily, $fontFamilyAlt;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 800;
    line-height: 17px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    background: #3ED70B;
    box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
    width: 100%;
    overflow: hidden;
  }

  &:disabled {
    opacity: 0.3;
  }
}

//-- quick input promo code
.quick-promo-input {
  background: #FFFFFF;
  border: 1px solid #22567C;
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.23);
  border-radius: 8px;
  text-indent: 12px;
  width: 169px;
  height: 48px;

  &::placeholder {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #3C3C3C;
  }
}

//-- quick registration input
.is-reg-amount {
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 100%;
  height: 44px;
  padding: 10px 8px;
  font-weight: 700;
  font-size: 22px;
  color: #FFF;
  line-height: 20px;
  background-color: #273242;
  border: 1px solid #394557;
  border-radius: 2px;
  text-align: center;
}

//- currency select in widget
.form-item-drop {
  select {
    -webkit-appearance: none;
    height: 44px;
    padding: 12px 20px;
    width: 98px;
    border-radius: 2px;
    border: 1px solid #394557;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #A5AFC0;
    background-color: #2F3A4A;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9InllcyI/Pjxzdmcgd2lkdGg9IjI4IiBoZWlnaHQ9IjI4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik05LjAyNCAxMWw1LjEyNyA1LjA1NkwxOS4zNTIgMTFsMS4wOTIuOTVMMTQuMTUxIDE4IDggMTEuOTUgOS4wMjQgMTF6IiBmaWxsPSIjQTdBRkJFIiBzdHlsZT0iIi8+PC9zdmc+);
    background-position: right 3px center;
    background-repeat: no-repeat;

    &:disabled {
      opacity: 0.3;
    }

    //&:focus {
    //  border-color: #143251;
    //  background: linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03)), linear-gradient(180deg, rgba(16, 84, 133, 0.66) 0%, rgba(21, 60, 88, 0.66) 100%);
    //}
  }
}



