$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.layout-cup-page {
  padding: 20px 20px 40px;
  margin: 0 auto;

  @include breakpoint($bp1) {
    padding: 20px  34px 40px;
  }
  @include breakpoint($bp2) {
    padding: 20px 32px 40px;
  }
  @include breakpoint($bp3) {
    max-width: 820px;
  }

  body.rtl-lang & {
   direction: rtl;
  }
}