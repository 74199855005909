$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.quick-registration-dropdown  {
  border-radius: 20px;
  position: relative;
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background-position: right 15px center;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQuODAxNzcgNi41TDkuOTI5MDcgMTEuNTU1NkwxNS4xMzAyIDYuNUwxNi4yMjIzIDcuNDUwMkw5LjkyOTA2IDEzLjVMMy43Nzc4MyA3LjQ1MDJMNC44MDE3NyA2LjVaIiBmaWxsPSIjQTVBRkMwIi8+Cjwvc3ZnPgo=);
    background-size: 22px;
    background-repeat: no-repeat;
    pointer-events: none;
    z-index: 1;

    body.rtl-lang & {
      background-position: left 15px center;
    }
  }

  .quick-registration-promo {
    position: relative;
    display: none;

    &.is-active {
      display: flex;
    }
  }

  &__bind {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  &.open {
    z-index: 35;
    min-height: 62px;
  }
}

html:not(.linda-has-popup) .entrance-bnr-wrapper,
.auth-popup {
  .quick-registration-dropdown.open {
    &::before {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjE5ODIgMTMuNUwxMC4wNzA5IDguNDQ0NDRMNC44Njk3NiAxMy41TDMuNzc3NzIgMTIuNTQ5OEwxMC4wNzA5IDYuNUwxNi4yMjIyIDEyLjU0OThMMTUuMTk4MiAxMy41WiIgZmlsbD0iIzU3NjQ3NyIvPgo8L3N2Zz4K);
      opacity: 0;
    }

    .quick-registration-promotions {
      position: absolute;
      height: unset;

      &::before {
        content: '';
        position: absolute;
        inset: -6px;
        background-color: #162334;
        border-radius: 4px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      }
    }

    .quick-registration-promo {
      display: flex;
      min-height: 52px;

      &::before {
        opacity: 1;
      }

      &__circle {
        display: block;
      }

      &__inner {
        &::after {
          display: none;
        }
      }

      &.is-active {
        .quick-registration-promo {
          &__inner {
            background-color: rgba(236, 253, 0, 0.08);
          }

          &__img {
            &.img-active {
              display: block;
            }

            &.img-default {
              display: none;
            }
          }

          &__title {
            color: #ECFD00;
          }

          &__text {
            color: #ffffff;
          }
        }
      }
    }
  }
}
