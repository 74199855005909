$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.cashbox-text {
  @include text(14px, 17px, 400, $grey);
  margin-bottom: 8px;

  &.cashbox-text_title {
    color: $black;
    margin: 20px 0 12px;
  }

  &.has-mb-4 {
    margin-bottom: 4px;
  }

  &_limit {
    color: $red;
    &::before {
      width: 20px;
    }
  }
  .no-payments & {
    @include text(14px, 20px, 400, $darkGrey);
    &.cashbox-text_title {
      @include text(16px, 20px, 400, $blueDark);
      margin: 0 0 8px;
    }
  }
}
