$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.screen {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &_game-page {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  &_game-page__info {
    height: 57px;
    text-align: center;
    padding-top: 3px;
    opacity: 0.4;
    p {
      font-size: 16px;
      line-height: 140%;
      margin-bottom: 2px;
    }
    a {
      color: $yellow;
    }
  }

  linda-footer {
    margin-top: auto;
  }

  &.is-full {
    padding: 0 !important;
  }

  body:not(.linda-has-user) & {
    padding-bottom: 48px;
  }

  &__wrap {
    width: 100%;
    height: 100%;
    position: relative;
  }
}
