$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.cashbox-wrapper {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 223px);
  @include breakpoint($bp1) {
    min-height: 392px;
  }
  .cashbox__history {
    margin-top: auto;
  }
}
