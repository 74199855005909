$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.tournament {
    position: relative;

    &__wrap {
        position: relative;
        margin-bottom: 92px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        .is-status-2 & {
            margin-bottom: 24px;
        }

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            height: 80px;
            width: 100%;
            z-index: 1;
            background-image: linear-gradient(to top, $blackRgb 9%, $blackTransparent 100%);
            background-size: 100% 80px;
            background-repeat: no-repeat;
        }
    }

    &__wrap-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
        padding-right: 20px;
        height: 212px;
    }

    &.is-status-2 &__img img {
        filter: grayscale(1)
    }

    &__img img {
        width: 131px;
        height: 200px;
        position: absolute;
        object-fit: contain;

        .tournament-ao & {
            height: 210px;
            top: 5px;

            @include m {
                width: 181px;
                height: 292px;
            }
        }
    }

    &__content {
        text-align: right;
        z-index: 1;
        margin-left: auto;
        max-width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 40px;
    }

    &__category {
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        margin-bottom: 4px;
        letter-spacing: 0.1em;
    }

    &__title {
        @include text(20px, 23px, bold);
        text-transform: uppercase;
        max-height: 46px;
        overflow: hidden;
        margin-bottom: 8px;
        white-space: break-spaces;
    }

    &__prize {
        @include text(24px, 28px, bold, $yellow);
        text-transform: uppercase;
        margin-bottom: 8px;
        @include ellipsis;
    }

    &__bet {
        @include text(12px, 14px, normal, $yellow);
        @include ellipsis;
        display: flex;
    }

    &__bottom {
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: -67px;
        z-index: 2;

        .is-status-2 & {
            bottom: 0;
        }
    }

    &__cup {
        width: 54px;
        height: 42px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url($cdn + '/rabona/tournament/tournament-item-icon.svg');
        background-size: 100%;
        display: flex;
        justify-content: center;
        padding-top: 6px;
        margin: 0 auto 4px;
    }

    &__cup-img {
        width: 17px;
        height: 23px;
    }

    &__cup-rank {
        color: #ffffff;
        font-weight: 700;
        font-size: 14px;
        line-height: 1em;
        margin-top: 6px;
    }

    &__link-block {
        @include ellipsis;
        padding: 0 15px;
        margin: 0 auto;
        display: flex;
        justify-content: center;

        body.rtl-lang & {
            direction: rtl;
        }
    }

    &__link {
        @include text(14px, 48px, bold, $white);
        letter-spacing: 0.01em;
        text-transform: uppercase;
        text-decoration: underline;
        margin: 0 6px;
        @include ellipsis;

        &.loading {
            pointer-events: none;
        }
    }

    &__timer {
        max-width: 168px;
        display: block;
        margin: 4px auto 0;
        .tournament-info &{
            margin-bottom: 20px;
        }
    }

    &__gamebox {
        .is-status-2 & {
            order: 1;
            margin-top: 24px;
        }

        body.rtl-lang & {
            direction: rtl;
        }
    }

    &__title-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-inline-end: 20px;

        body.rtl-lang & {
            direction: rtl;
        }
    }

    &__link-up {
        position: relative;
    }

    &__table {
        margin-top: 8px;

        body.rtl-lang & {
            direction: rtl;
        }
    }

    & .icon-arrow-down__before:before {
        content: '';
        width: 16px;
        height: 16px;
        display: block;
        position: absolute;
        transform: rotate(-180deg);
        inset-inline-start: 0;
    }

    &__end {
        position: relative;
        background: $red;
        height: 24px;
        width: 100%;
        box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.25);
        @include text(12px, 24px, bold);
        text-align: center;
        z-index: 1;
    }


    @include breakpoint($bp1 ) {
        &__wrap-content {
            max-width: 400px;
            margin: 0 auto;
            padding-right: 0;
        }

        &__content {
            max-width: 249px;
        }

        &__title-block {
            padding-left: 40px;
            padding-right: 40px;
        }

        &__table {
            margin: 8px 20px 0;
        }
    }

    @include breakpoint($bp2 ) {
        &__wrap {
            margin-bottom: 24px;
        }

        &__wrap-content {
            max-width: 100%;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow: hidden;
            padding: 0 20px;
        }

        &__bottom {
            width: auto;
            left: 50%;
            transform: translateX(-50%);
            top: 20px;
        }

        &__img {
            position: relative;
            width: 181px;
            height: 276px;
        }

        &__content {
            order: 1;
            margin-left: 0;
            max-width: 279px;
            padding-bottom: 0;
        }

        &__title {
            @include text(28px, 33px);
            max-height: 66px;
        }

        &__prize {
            @include text(28px, 33px);
        }

        &__cup {
            width: 88px;
            height: 68px;
            padding-top: 10px;
            margin-bottom: 8px;
        }

        &__cup-img {
            width: 28px;
            height: 37px;
        }

        &__cup-rank {
            font-size: 20px;
            margin-top: 10px;
        }

        &__timer {
            margin-top: 8px;
        }
    }

    &.tournament-euro {
        .tournament {
            &__banner {
                position: relative;
                overflow: hidden;
                background-size: cover;
                margin-bottom: 12px;
                background-position: center;
            }

            &__background-img {
                position: absolute;
                top: -115px;
                right: 50%;
                transform: translateX(61%);
                max-width: none;
                width: 580px;
                width: 509px;

                @include breakpoint($bp1) {
                    transform: translateX(61%);
                    width: 560px;
                    top: -125px;
                }

                @include breakpoint($bp2) {
                    width: 600px;
                    top: -135px;
                    transform: translateX(60%);
                }

                @include breakpoint($bp3) {
                    transform: translateX(65%);
                }

            }

            &__banner-wrap {
                height: 258px;
                overflow: hidden;
                background-size: cover;

                @include breakpoint($bp2) {
                    height: 274px;
                }
            }

            &__banner-content {
                position: relative;
                text-align: right;
                padding: 42px 20px 40px 0;
                z-index: 3;

                @include breakpoint($bp1) {
                    padding-right: 40px;
                }

                @include breakpoint($bp2) {
                    padding-top: 50px;
                }

                @include breakpoint($bp3) {
                    padding-right: 160px;
                }
                
            }

            &__banner-img {
                z-index: 2;
                position: absolute;
                width: 353px;
                height: 271px;
                background-size: contain;
                background-repeat: no-repeat;
                left: 50%;
                bottom: 5px;
                transform: translateX(-71%);

                @include breakpoint($bp1) {
                    transform: translateX(-81%);
                }

                @include breakpoint($bp2) {
                    width: 431px;
                    height: 373px;
                    bottom: -70px;
                    transform: translateX(-79%);
                }
                
                @include breakpoint($bp3) {
                    transform: translateX(-106%);
                }
            }

            &__title {
                margin-bottom: 12px;

                @include breakpoint($bp2) {
                    font-size: 20px;
                    max-width: none;
                    line-height: 23px;
                }
            }

            &__bet-btn {
                padding: 12px 24px;
                min-width: 102px;
            }

            &__prize {
                font-size: 40px;
                line-height: 1.17;
                margin-bottom: 14px;
            }

            &__link-block {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 3;
                display: flex;
                justify-content: center;
                column-gap: 24px;
                letter-spacing: 0.14px;

                &::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 48px;
                    background: linear-gradient(0deg, #0C1221 8.75%, rgba(12, 18, 33, 0.00) 100%);
                    z-index: -1;
                }
            }

            &__info {
                padding: 24px 12px 12px;
                margin-bottom: 20px;
                border-radius: 2px;
                background-color: #162334;

                @include breakpoint($bp2) {
                    display: grid;
                    align-items: center;
                    grid-template-columns: 1fr 1fr;
                    column-gap: 4px;
                    padding: 12px;
                }
            }
            
            &__timer-text {
                text-transform: uppercase;
                font-size: 20px;
                text-align: center;
                margin-bottom: 12px;
            }

            &__timer {
                margin-bottom: 20px;

                @include breakpoint($bp2) {
                    margin-bottom: 0;
                }
            }
        }

        .tournament-how-to {
            padding: 20px 12px 12px;
            margin-bottom: 20px;
            border-radius: 2px;
            background-color: #162334;

            &__title {
                margin-bottom: 16px;
            }

            &__list {
                display: grid;
                gap: 4px;

                @include breakpoint($bp1) {
                    grid-template-columns: 1fr 1fr;
                }
            }

            &__item {
                padding: 6px 10px 6px 6px;
                background-color: #0B1222;
                border-radius: 2px;
                margin-bottom: 0;
                line-height: 1.2;
            }

            &__item-text {
                display: flex;
                align-items: center;
                color: #A5AFC0;
                font-size: 14px;

                &:before {
                    margin-right: 8px;

                    body.rtl-lang & {
                        margin-right: 0;
                        margin-left: 8px;
                    }
                }
            }
        }
    }

    &__events {
        width: 100%;
        margin: 0 auto 20px;

        @include breakpoint($bp1) {
            margin-bottom: 16px;
        }

        @include breakpoint($bp3) {
            margin-bottom: 12px;
        }

        .title {
            margin-left: 0;
        }

        .game-box-title {
            margin: 0;

            &::after {
                margin-right: 0;
            }

            body.rtl-lang & {
                flex-direction: row-reverse;

                &::after {
                    transform: rotate(180deg);
                    margin: 0;
                }

                .home__events-link {
                    margin: 0 10px 0 3px;
                }

                .title {
                    margin: 0 0 0 auto;
                }
            }
        }
    }

    &__promotions {
        margin-bottom: 20px;

        @include breakpoint($bp1) {
            margin-bottom: 16px;
        }

        @include breakpoint($bp3) {
            margin-bottom: 12px;
        }

        body.rtl-lang & {
            text-align: right;
        }

        .title {
            margin-left: 0;
            margin-right: 0;
        }
    }
    &-page-wrapper{
        &.preloader {
            min-height: 300px;
        }
    }
}

.tournament-list-info {
    display: grid;
    row-gap: 4px;

    @include breakpoint($bp2) {
        flex-grow: 1;
    }

    &__item {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 10px 16px 10px 40px;
        border-radius: 2px;
        background-color: rgba(12, 18, 33, .8);
        font-size: 16px;
        line-height: 1.5;

        body.rtl-lang & {
            padding: 10px 40px 10px 16px;
            flex-direction: row-reverse;
        }
    }

    .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 6px;
        width: 28px;
        height: 28px;
        background-size: 17px;
        background-position: 50% 50%;
        background-repeat: no-repeat;

        body.rtl-lang & {
            left: auto;
            right: 6px;
        }
    }
}

.tournament-grid {
    padding: 0 12px;

    @include breakpoint($bp3) {
        display: grid;
        column-gap: 12px;
        grid-template-columns: 1fr 340px;
        grid-template-areas: 'main leaderboard'
                             'terms leaderboard';
    }

    &__main {
        @include breakpoint($bp3) {
            grid-area: main;
            min-width: 0;
        }
    }

    &__side {
        @include breakpoint($bp3) {
            grid-area: leaderboard;
        }
    }

    &__terms {
        @include breakpoint($bp3) {
            grid-area: terms;
        }
    }
}

.tournament-leaderboard {
    &__wrap {
        scroll-margin: 50px;
        padding-top: 24px;
        margin-bottom: 20px;
        background-color: #162334;
        align-self: start;

        @include breakpoint($bp1) {
            margin-bottom: 16px;
        }

        @include breakpoint($bp3) {
            margin-bottom: 0;
        }
    }

    &__title {
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 12px;
        font-size: 20px;
        line-height: 23px;

        body.rtl-lang & {
            text-align: center;
        }
    }
}

.tournament-slider {
    position: relative;
    padding: 0 32px;

    .slick-track {
        display: flex;

        .slick-slide {
            height: auto;

            & > div {
                height: 100%;
            }
        }
    }

    &__container {
        height: 100%;
    }

    &__item {
        display: flex;
        height: 100%;
        background: #CC0034;
        color: #fff;
        min-height: 132px;

        &.not-available {
            background-color: #162334;
            color: #576477;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        body.rtl-lang & {
            align-items: flex-end;
            text-align: right;
        }
    }

    &__img {
        width: 45%;
        background-size: cover;
        background-position: 50% 50%;

        .not-available & {
            filter: grayscale(1);
        }
    }

    &__content {
        width: 55%;
        padding: 12px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 14px;
    }

    &__title {
        margin-bottom: 8px;
        line-height: 1.17;
    }

    &__offer {
        color: #ECFD00;
        line-height: 1;
        font-size: 20px;
        margin-bottom: 24px;

        .not-available & {
            color: #576477;
        }
    }

    &__btns-wrap {
        display: flex;
        flex-direction: column;
        row-gap: 4px;

        @include breakpoint($bp1) {
            flex-direction: row;
        }

        .not-available & {
            display: none;
        }
    }

    &__btn {
        min-width: 142px;
    }

    &__link {
        padding: 12px;
        color: #fff;
        text-decoration: underline;
    }

    &__label {
        display: inline-block;
        padding: 6px 20px;
        font-size: 16px;
        font-style: italic;
        background-color: #576477;
        color: #FFF;
        clip-path: polygon(11px 0, 100% 0, calc(100% - 11px) 100%, 0 100%);
        margin-bottom: 12px;
    }
}

.tournament-slider-pagination {
    &__arrow {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 32px;
        border-radius: 2px;
        border: 1px solid #273242;
        background: #1C2738;

        &::before {
            content: '';
            position: absolute;
            inset: 0;
            background-image: var(--icon-arrow);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: 50% 50%;
            filter: invert(0.4);
            transform: rotate(180deg);
        }

        &::after {
            content: '';
            position: absolute;
            top: -1px;
            bottom: -1px;
            left: 100%;
            width: 12px;
            z-index: 1;
            background: linear-gradient(to right, rgba(12, 18, 33, .8), transparent);
        }

        &.is-next {
            left: auto;
            right: 0;

            &::before {
                transform: rotate(0deg);
            }

            &::after {
                left: auto;
                right: 100%;
                background: linear-gradient(to left, rgba(12, 18, 33, .8), transparent);
            }
        }
    }
}

.tournament-terms {
    border-radius: 2px;
    background: #162334;
    padding: 24px 12px;
    color: #A5AFC0;
    font-size: 16px;
    line-height: 1.2;
    scroll-margin: 50px;

    body.rtl-lang & {
        text-align: right;
    }

    &__title {
        font-size: 20px;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 12px;
        line-height: 1;
        color: #fff;
    }

    p {
        margin-bottom: 10px;

        span:first-child {
            body.rtl-lang & {
                float: right;
            }
        }
    }

    ul {
        margin-bottom: 10px;

        li {
            margin-bottom: 5px;
        }
    }

    &__content {
        max-height: 146px;
        overflow: hidden;

        .is-open & {
            max-height: none;
        }
    }
}

.tournament-terms-dropdown {
    position: relative;
    display: block;
    color: #ECFD00;
    text-decoration: underline;
    text-align: center;
    &::before{
        content: '';
        position: absolute;
        bottom: 100%;
        left: 0;
        width: 100%;
        height: 30px;
        background: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(22,35,52,1) 100%);
    }

    .tournament-terms.is-open &::before{
        content: none;
    }

    &__text_less {
        display: none;

        .is-open & {
            display: block;
        }
    }

    &__text_more {
        .is-open & {
            display: none;
        }
    }
}
