$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.pending-modal {
  &__title {
    font-size: 24px;
    padding-bottom: 12px;

    @include breakpoint($bp1) {
      text-align: center;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 20px;
    border-bottom: 2px solid #CC0034;
    color: #A5AFC0;
  }
  &__vbtns {
    padding-top: 20px;
    text-align: center;

    button {
      width: 100%;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      padding: 16px 20px;
    }
  }

  &__wcancel {
    display: grid;
    margin-top: 20px;
    color: #0b1222;
    font-size: 18px;
    line-height: 22px;
  }

  .amount-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__item {
    background: #ffffff;
    min-height: 64px;
    box-sizing: border-box;
    padding: 12px 8px 12px 0;
    display: grid;
    grid-template-columns: 120px auto 40px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #CDD2D9;
  }

  &__close {
    background: #ECFD00;
    border-radius: 1px;
    width: 40px;
    height: 40px;
    object-fit: none;
  }

  &__date {
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    text-align: center;
    color: #2A394E;
  }

  &__amount {
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    text-align: center;
    text-transform: capitalize;
    color: #0C1221;
  }

  &__block {
    max-width: 150px;
    text-align: center;
  }

  &__img {
    width: 110px;
    height: 30px;
    object-fit: contain;
    display: block;
  }
}