$build: 1739288186250;
$cdn: "/joxi";
$cdnProject: undefined;
.layout-popup-form {
  width: 100%;
  max-width: 280px;
  margin: 0 auto;
  padding: 32px 0 40px;

  position: relative;

  &__btn-wrap{
    display: flex;
    justify-content: center;
  }

  &.is-padding-0 {
    padding: 0;
  }

  ol {
    padding-left: 32px;
  }

  .popup-text {
    margin: 12px 0 20px;
  }

  .btn-back-popups {
    margin: 0 0 3px;
  }

  .form-item:not(.form-checkbox) {
    margin-top: 22px;
  }

  .link-btn-up {
    margin: 8px 0;
  }
  .link-btn {
    margin-top: 8px;
  }


  button {
    margin-top: 20px;
    width: 100%;
  }

  @include breakpoint( $bp2 ){
    max-width: 320px;
  }

  @include breakpoint( $bp3 ){
    max-width: 440px;
  }

  &.is-wide {
    width: 100% !important;
    max-width: 100% !important;
  }

}